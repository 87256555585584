<div class="container">

  <div class="row">
    <div class="col-sm-12 bgimg"></div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <h2>Error - Page Not Found</h2>
      <p>We're sorry, the page you requested was not found.</p>
      <p>We'll get this fixed as soon as possible. In the meantime... please 
      <a href="/" routerLink="/">return to the home page.</a></p>
    </div>
  </div>

</div>