import { Injectable } from '@angular/core';

@Injectable()
export class LinkService {

  constructor() { }

// provide links from JSON doc

}
