import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
// import { RestService } from './../services/rest.service';
import { UserService } from './../services/user.service';
import { EventService } from './../services/event.service';
import { AnnouncementService } from './../services/announcement.service';
import { DocumentService } from './../services/document.service';
import { GoogleAnalyticsService } from './../services/analytics.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { environment } from './../../environments/environment';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  userList = this.user.devUsers;
  showCal = false;
  isPTS = false;


  // data for demo
  latestPost = {};
  newsBtnText = "More News";
  displayMore = false;
  newsList = [];
  latestPosts = [];
  newsCount = 10;
  headlines = 3;

  linkList = [
    {
      'displayname': 'Product Information Management (PIM)',
      'desc' : 'Set up and manage your products for sale on tractorsupply.com (formerly ATLAS)',
      'url' : environment.pim,
      // 'desc' : 'This page is currently unavailable due to routine maintenance.', //maintenance page
      // 'url' : '/maintenance/index.html', // maintenance page
      'thumbnail': '',
      'order': '1',
      'level': '', // roles allowed to view this link along with all admins
      'show': 'TSC',
      'contact': 'atlas@tractorsupply.com'
    },
    {
      'displayname': 'Vendor Information System (VIS)',
      'desc' : 'Custom reporting from the Vendor Information System',
      'url' : 'https://vis4app.tractorsupply.com',
      'thumbnail': '',
      'order': '2',
      'level': '',
      'show': '',
      'contact': 'VISSupport@tractorsupply.com'
    },
    {
      'displayname': 'Retail Media | Campaign Management Portal', // Media Network | Campaign Management Portal
      'desc' : 'A one-stop repository for contracts/insertion Orders, billing, files, and asset/creative file management for your Retail Media Network campaigns.',
      'url' : 'https://tscvendorfusion.themarsagency.com/',
      'thumbnail': '',
      'order': '6',
      'level': '',
      'show': '',
      'contact': 'TractorSupplyMediaNetwork@TractorSupply.com'
    },
    {
      'displayname': 'Vendor Compliance',
      'desc' : 'Learn more about the ways Tractor Supply Co. strives to outperform the competition',
      'url' : 'https://tsco.traversesystems.com/#/login ',
      'thumbnail': '',
      'order': '5',
      'level': '',
      'show': 'TSC',
      'contact': 'TSCCOMPLIANCE@tractorsupply.com'
    },
    {
      'displayname': 'Vendor Power BI Dashboards', // Media Network | Campaign Management Portal, Retail Media: Campaign Performance Reports
      'desc' : 'Dashboards that TSC has made available for Vendors are available on the link.<br /><br />Questions? Applicable contact emails are listed on each individual dashboard.',
      'url' : 'campaign-performance',
      'thumbnail': '',
      'order': '6',
      'level': '',
      'show': '',
      'contact': ''
    },
    {
      'displayname': 'Accounts Payable (AP) Portal',
      'desc' : 'Search for invoices or payments',
      'url' : 'https://tsc.apexanalytix.com/',
      'thumbnail': '',
      'order': '4',
      'level': 'Accounting',
      'show': '',
      'contact': 'AskAP@tractorsupply.com'
    },
  {
      'displayname': 'Vendor Transaction Portal (VTP)',
      'desc' : 'Transaction Error Application<br />Find Invoice and ASN (DC Only) Transaction Errors<br />The ASN is not required for direct to store shipments.',
      'url' : 'https://vtp.tractorsupply.com',
      'thumbnail': '',
      'order': '7',
      'level': '',
      'show': '',
      'contact': 'EDI@TractorSupply.com'
    },
    {
      'displayname': 'VTP Shipment Delay Application',
      'desc' : 'Update delayed shipment information.',
      'url' : 'https://vtp.tractorsupply.com',
      'thumbnail': '',
      'order': '8',
      'level': '',
      'show': '',
      'contact': 'DropShipMC@TractorSupply.com'
    },
    {
      'displayname': 'Ariba Network Supplier Portal',
      'desc' : 'Manage your Ariba Network Supplier Account',
      'url' : 'https://service.ariba.com/Supplier.aw/125032058/aw?awh=r&awssk=Uh6e4e_0&dard=1',
      'thumbnail': '',
      'order': '9',
      'level': '',
      'show': 'TSC',
      'contact': 'ProcureToPay@tractorsupply.com'
    },
    {
      'displayname': 'Manhattan TLM',
      'desc' : 'Transportation Portal',
      'url' : 'https://mip-transportation.tractorsupply.com/login.jsp ',
      'thumbnail': '',
      'order': '9',
      'level': '',
      'show': 'TSC',
      'contact': 'TSPSupport@tractorsupply.com'
    },
    {
      'displayname': 'Expeditors Booking Tool',
      'desc' : 'Booking Import Freight',
      'url' : 'https://omb.expeditors.com/omb/index.jsp ',
      'thumbnail': '',
      'order': '9',
      'level': '',
      'show': 'TSC',
      'contact': 'imports_transportation@tractorsupply.com'
    },
    {
      'displayname': 'Cleartrack',
      'desc' : 'Import Quote Management',
      'url' : 'https://www.cleartrack.com/content/login.jsp ',
      'thumbnail': '',
      'order': '10',
      'level': '',
      'show': 'TSC',
      'contact': 'imports@tractorsupply.com'
    }
  ];

  modalContent = {
    'header': '',
    'body': '',
    'footer': ''
  }



  constructor(
    public user: UserService,
    public events: EventService,
    public msg: AnnouncementService,
    public docs: DocumentService,
    private router: Router,
    //public rest: RestService,
    public ga: GoogleAnalyticsService,
  ) { }

  ngOnInit() {

   if (this.newsList && this.newsList.length>0){
     this.latestPost = this.newsList[0];
   }

   // apply sort order to links
   this.linkList = this.linkList.sort((a:any, b:any) => {return a.order - b.order;});

   this.refreshEvents();
   this.refreshNews();

   console.log('User information', this.user.loggedInUser);

  }

  showMoreNews(){
    return this.displayMore;
  }

  toggleMoreNews(){
    if(this.displayMore){
      this.displayMore = false;
      this.newsBtnText = "More News";
    } else {
      this.displayMore = true;
      this.newsBtnText = "Hide List";
    }
  }

  refreshEvents(){
    // get latest event list
   this.events.retrieveEvents().subscribe(
      data => {
      this.showCal = true;
      }, error => {
        console.log('there was an error', error);
        this.showCal = true;
      }
    );
  }

  refreshNews(){
    this.latestPost = null;
    this.latestPosts = [];
    this.newsList = [];
    this.docs.selectedCategory = '';
    console.log('reset category', this.docs.selectedCategory);
    return this.msg.retrieveAnnouncements().subscribe(
      data => {
       // process by pubdate and user (user should be in request)
       let now = moment().format();
       let msglist = data.filter(a => moment(a.publishDate).isSameOrBefore(now) && (!a.expiryDate || moment(a.expiryDate).isSameOrAfter(now)));
       console.log('removed expired', msglist);

       const role = this.user.getUserRole();
       console.log('User Role: ' + role, this.user.loggedInUser);

          // petsense
        if(this.user.loggedInUser && this.user.loggedInUser.vendorType && this.user.loggedInUser.vendorType.indexOf('PTS')>-1) {
          this.isPTS = true;
        } else {
          this.isPTS = false;
        }

      // first, filter by lob and buyer id
      // TIBCO-3596 Audience Filters
      const currUser = this.user.loggedInUser;

      // filter by vendor list, lob, or buyer id
      if(!role || (role && role.toString().toUpperCase() !== 'TSCADMIN')) {
        // start with announcements with no filters, visible to all
        let combinedAnn = msglist.filter(a => (!a.lob || a.lob.length == 0 ) && (!a.buyerId || a.buyerId.length == 0) && (!a.vendor || a.vendor.length == 0));
        let ann = [];
        
        console.log('LOB', currUser.lob);
        console.log('VendorList', currUser.vendorList);
        console.log('BuyerID', currUser.buyerId);
        console.log('general announcements', combinedAnn);

        if(currUser && currUser.lob) {
          // LOB first
          let lob = [];
          if (currUser.lob.indexOf(',') > -1) {
            // parse comma separated list for user
            lob = currUser.lob.split(',');
          } else {
            // lob.push(currUser.lob);
            // service returns array
            lob = currUser.lob;
          }
          for (let l of lob){
            const lobAnn = msglist.filter(a => a.lobArr && a.lobArr.indexOf(l) > -1);
            ann = ann.concat(lobAnn);
            console.log('announcements for LOB: ' + l, ann);
          }
        }
          
        
        if (currUser && currUser.vendorList) {
          // Vendor List
          let vids = [];
          /* // old vendor number list
          if (currUser.vendorNumber.indexOf(',') > -1) {
            vids = currUser.vendorNumber.split(',');
          } else {
            vids.push(currUser.vendorNumber);
          } */

          for(let vendor of currUser.vendorList) {
            if(vendor && vendor.VendorID && vendor.VendorID !== '') {
              vids.push(vendor.VendorID);
            }
          }

          console.log('user vendor ids', vids);
          for (let v of vids) {
            const vendorAnn = msglist.filter(a => a.vendorIds && a.vendorIds.indexOf(v) > -1);
            ann = ann.concat(vendorAnn);
            console.log('announcements for VendorID: ' + v, ann);
          }
        } 
        
        if (currUser && currUser.buyerId) {
          // Buyer ID
          let bids = [];
          if (currUser.buyerId.indexOf(',') > -1) {
            bids = currUser.buyerId.split(',');
          } else {
            // bids.push(currUser.buyerId);
            bids = currUser.buyerId;
          }
          for (let b of bids) {
            const buyerAnn = msglist.filter(a => a.buyerArr && a.buyerArr.indexOf(b) > -1);
            ann = ann.concat(buyerAnn);
            console.log('announcements for BuyerID: ' + b, ann);
          }
        }

        // add user posts to public list
        for (let a of ann) {
          if (a && !combinedAnn.find(m => m.id === a.id)){
            // if it's not already in the list, add it
            combinedAnn.push(a);
          }
        }
        console.log('filtered Announcements', combinedAnn);
        msglist = combinedAnn;


      // end new filters

    //   if (role && role.toString().toUpperCase() === 'TSCADMIN'){
         // show all posts
    //   } else if (role && role.toString().toUpperCase() === 'VENDORADMIN'){
      if (role && role.toString().toUpperCase() === 'VENDORADMIN'){
         msglist = msglist.filter(m => m.roleName && m.roleName.indexOf('TSCAdmin') < 0); // show all except TSCAdmin
         // msglist = msglist.filter(m => m.roleName!=='TSCAdmin' && m.roleName.indexOf('VendorAdmin') > -1 || (!m.roleName || m.roleName === '' || m.roleName === 'null' || m.roleName.toLowerCase() === 'all' || m.roleName.indexOf('Vendor') > -1));
        console.log('vendor admin list', msglist);

        } else {

        // generic vendor announcements
         let vendorMsg = msglist.filter(m => m.roleName!=='VendorAdmin' && m.roleName!=='TSCAdmin' && m.roleName!=='TSC_Buyer' && m.roleName!=='PTS_Buyer' && (!m.roleName || m.roleName === '' || m.roleName === 'null' || m.roleName.toLowerCase() === 'all' || m.roleName === 'Vendor'));
        // handle multiple roles
        let uRoles = role;
        if (role && role.indexOf(',') > -1) {
           // console.log('user role contains commas, split it!');
          uRoles = role.split(/\s*,\s*/g);

          for (let r of uRoles){
            let targetedNews = msglist.filter(m => m.roleName.indexOf(r) > -1);
            console.log('announcements for ' + r, targetedNews);
            for (let a of targetedNews){
              let existing = vendorMsg.find(n => n.id === a.id); // remove duplicates
              if(!existing){
                vendorMsg.push(a);
              }
            }
          }
        } else {
          // handle single role
          const r = role;
          let targetedNews = msglist.filter(m => m.roleName.indexOf(r) > -1);
            console.log('announcements for ' + r, targetedNews);
            for (let a of targetedNews){
              let existing = vendorMsg.find(n => n.id === a.id); // remove duplicates
              if(!existing){
                vendorMsg.push(a);
              }
            }
        }

        // || m.roleName.indexOf(role) > -1
        // replace msglist with list for role
        if (!role || (role && role.toString().toUpperCase() !== 'TSCADMIN'  && role.toString().toUpperCase()!=='VENDORADMIN')){
          msglist = vendorMsg;
        }
       }
      
        // filter by vendor type
        console.log('filter by vendor type', currUser.vendorType);
        let currentVendorTypes = [];
        if (currUser.vendorType && currUser.vendorType !== '') {
          // vendorType may be comma separated, force to array
          if (currUser.vendorType.indexOf(',')>-1) {
            // add unique
            const vt = currUser.vendorType.split(',');
            if (vt && vt.length > 0) {
              for (let t of vt) {
                const dupType = currentVendorTypes.find(v => v == t);
                if (!dupType) { currentVendorTypes.push(t);}
              }
            }
          } else {
            currentVendorTypes.push(currUser.vendorType);
          }
        

          console.log('Vendor Type', currentVendorTypes);
          if (currentVendorTypes.length > 0) {
            msglist = msglist.filter(t => !t.vendorType || t.vendorType == '' ||  t.vendorType == 'all' || this.findVendorType(t.vendorType, currentVendorTypes));
          }
        }

        //if (currUser.vendorType && currUser.vendorType !== '') {
        //  msglist = msglist.filter(t => !t.vendorType || t.vendorType == '' ||  t.vendorType.indexOf(currUser.vendorType)>-1);
        // }

        // filter by vendor attributes, in currUser.vendorList
        console.log('filter by vendor list', currUser.vendorList);
        if (currUser.vendorList) {
          // msglist = msglist.filter(a => !a.vendorAttr || a.vendorAttr == '' ||  a.vendorAttr.indexOf(currUser.vendorAttributes)>-1);
          msglist = msglist.filter(a => !a.vendorAttr || a.vendorAttr == '' || a.vendorAttr == 'all'||  this.showVendorAttr(a));
        } else {
          msglist = msglist.filter(a => !a.vendorAttr || a.vendorAttr == '' || a.vendorAttr == 'all');
        }

      }
       // sort announcements by date
       msglist = this.user.sortByDate(msglist,'publishDate').reverse();
      console.log('announcements for this user', msglist);


       // get most recent 10 (newsCount)
       for (let m = 0; m < this.newsCount; m++) {
         if (msglist[m]){
            if( m > this.headlines-1){
              this.newsList.push(msglist[m]);
            } else {
              this.latestPosts.push(msglist[m]);
            }
         }
       }
       // this.latestPost = this.newsList[0];
      }

    );
  }

  findVendorType(csvField,typesArr) {
    for (let t of typesArr) {
      if (csvField.indexOf(t)>-1) {
        return true;
      }
    }
    return false;
  }

  visitLink(link){
    if (link.url && link.url.indexOf('http')<0) { 
      // handle relative page links
      this.router.navigate([link.url]);
    } else {
      // ga tracking
      console.log('outbound link: ', link);
      this.ga.track('/VendorPortal/outbound/' + link.displayname);

      // redirect
      window.location.href = link.url;
    }
  }

  goToALink(msg) {
    console.log(msg);
    if (msg.announceLink && msg.announceLink !== '') {
      console.log('visiting ' + msg.announceLink);
      const aLink = document.createElement('a');
      aLink.target = '_blank';
      aLink.href = msg.announceLink;
      aLink.setAttribute('visibility', 'hidden');
      aLink.click();
    }
  }

  viewAttachment(file,modal) {
    // access download function
    console.log(file);
    let downloadPath = file.docPath;
    this.docs.downloadDocument(downloadPath, true).subscribe(
      data => {
        console.log('downloading attachment from ' + file.id);
        saveAs(data, file.fileName);
        this.ga.track('/VendorPortal/attachment/download/' + file.fileName);
      },
      error => {
        console.log(error);
        this.modalContent.header = 'Error';
        this.modalContent.body = 'There was a problem downloading the document.';
        this.modalContent.footer = error.text();
        this.ga.track('/VendorPortal/error/download/' + file.fileName);
        modal.show();
      }
    );
  }

  showVendorAttr(msg) {
    // include this announcement/event in the list
    let showMe = true;
    const vendorsWithAttributes = this.user.loggedInUser.vendorList.find(v => v.VendorAttribute && v.VendorAttribute !== '');
    if (msg.vendor === '' || msg.vendorIds.length === 0) {
      // no vendor ID, check for any attribute in vendorList
      // if announcement/event has any Vendor Attribute, only show to those vendors
      console.log('message attributes?', msg.vendorAttr, msg.vendorAttrArr);
      if (msg.vendorAttrArr && msg.vendorAttrArr.length > 0) {
        showMe = false;
        if(vendorsWithAttributes) {
          for (let attr of msg.vendorAttrArr){
            for (let vnum of vendorsWithAttributes) {
              console.log('looking for ', attr, vnum);
              if (vnum.VendorAttribute.indexOf(attr)>-1) {
                showMe = true;
                console.log('attribute found, show', vnum.VendorAttribute, attr, msg);
              }
            }
          }
        }
      }

    } else {
      // message assigned to vendor id, check if attribute is also assigned
      console.log('message attributes?', msg.vendorAttr, msg.vendorAttrArr);
      if (msg.vendorAttrArr && msg.vendorAttrArr.length > 0) {
        showMe = false;
        if(vendorsWithAttributes){
          for (let v of msg.vendorIds) {
            const thisVendor = vendorsWithAttributes.find(x => v.VendorID === v);
            if (thisVendor && thisVendor.VendorAttributes) {
              for (let attr of msg.vendorAttrArr) {
                console.log('looking for ', attr, thisVendor.VendorAttributes);
                if (thisVendor.VendorAttributes.indexOf(attr)>-1) {
                  showMe = true;
                  console.log('attribute found for ' + v, attr, thisVendor, msg);
                }
              }
            }
          }
        }
      }
    }
    console.log(showMe, msg);
    return showMe;
  }

// demo functions

  switchUser(id){
    this.user.setUser(id);
    this.refreshNews();
    this.refreshEvents();
  }

  switchBG(){
    let div = document.querySelector('.section-splash');
    if (div.classList.contains('altimg')) {
      div.classList.remove('altimg');
    } else {
      div.classList.add('altimg');
    }
  }

  selectDocumentCategory(cat){
    this.docs.selectedCategory = cat;
  }

}
