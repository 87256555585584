import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {newUserRequest, User, UserService} from "../services/user.service";
import {Router} from "@angular/router";
import * as moment from 'moment';
import {VendorInvite} from "../modal/vendor.invite";
import {NgForm} from "@angular/forms";
import {InviteService} from "../services/invite.service";
import { DatePipe } from '@angular/common';
import * as states from './../../assets/statelist.json';
import * as countries from './../../assets/countrylist.json';
import {VendorApiService} from '../vendor-services/vendor-api.service';

import { GoogleAnalyticsService } from './../services/analytics.service';
import {CompanyNameRequest} from '../modal/CompanyNameRequest.model';
import { CompanyNameResponse} from '../modal/companyname-response.model';

import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PopupDialogService } from '../services/popup-dialog.service';
import { PopupDialogData, PopupDialogResponse } from '../modal/popup-dialog.model';

@Component({
  selector: 'app-vendor-invite',
  templateUrl: './vendor-invite.component.html',
  styleUrls: ['./vendor-invite.component.css']
})
export class VendorInviteComponent implements OnInit {
@ViewChild('modalMessage') modalMessage;
@ViewChild('modalError') modalError;
  selectedUser = new User();
  userValue = "";
  venodorInvite: VendorInvite = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    vendorType: 'Domestic',
    country: '',
    state: ''
  };
  vendorData;
  buyerData;
  buyerDetails;
  parentVendorNameValue;
  placeHolderImport = false;
  placeHolderDomestic = false;
  sortDir = 'asc';
  message: string;
  public disableSubmit = false;
  modalContent = {
    'header':'Success',
    'body':'User added successfully.',
    'footer':''
  }
  showMsg: string = "";
  unamePattern = "^[0-9_-]{8,15}$";
  today = Date.now();
  tomorrow = moment(this.today).add(1,'day');

  allStates;
  stateList;
  countryList;
  vendorNumberError = false;
  isPlaceHolderVendor;

  //NSC-53
  isLoading = false;
  onboardingFormSubmit: boolean= true;
  onboarindReasonText:string='';
  @ViewChild('vendorNameInputTextRef') vendorNameInputTextRef:ElementRef;
  @ViewChild('form') formRef:NgForm;
  constructor(
    private api: VendorApiService,
    private users: UserService,
    private inviteService: InviteService,
    private router: Router,
    private ga: GoogleAnalyticsService,
    private poupDialogService: PopupDialogService
  ) { }

  ngOnInit() {

    if ((!this.users.isLoggedIn()) || (this.users.getUserRole() !== 'TSC_Buyer' && this.users.getUserRole() !== 'PTS_Buyer')){
      console.log('not authorized');
      // redirect to home/login
      this.router.navigate(['']);
    }else{
      console.log('authorized');
    }
    if(this.users.getUserRole() === 'TSC_Buyer'){
      this.venodorInvite.buyerCompany='TSC';
    }else if(this.users.getUserRole() === 'PTS_Buyer'){
      this.venodorInvite.buyerCompany='PETSENSE';
    }
    this.users.getUser();
    this.getBuyerInfo();
    // retrieve country and state lists from JSON in assets folder
    this.allStates = (states as any).default;
    this.countryList = (countries as any).default;
    // set default date
    this.venodorInvite.expectedOrderDate = moment(this.today).add(2, 'weeks').format('YYYY-MM-DD');
  }


  addNewUser(){
    this.selectedUser = new User();
  }

  toggleDirection() {
    if (this.sortDir === 'asc') {
      this.sortDir = 'desc';
    } else {
      this.sortDir = 'asc';
    }
  }

  clearMessage(){
    this.showMsg="";
    this.userValue="";
    this.router.navigateByUrl('/');
  }

  clearValues(){
    this.showMsg="";
    this.userValue="";
  }

  setCountry(abbr){
    this.stateList = this.allStates.filter(s => s.COUNTRY === abbr);
    this.venodorInvite.state = ''; // clear state
    // console.log(this.stateList, abbr);
  }

  onVendorTypeChange() {
    console.log('vendor type changed to ', this.venodorInvite.vendorType);
    if (this.venodorInvite.vendorType === 'PlaceHolder-Domestic') {
      // populate US state list
      this.venodorInvite.country = 'US';
      this.setCountry('US');
      this.setBuyerContactInfo();
      
    } if (this.venodorInvite.vendorType === 'PlaceHolder-Import') {
      this.venodorInvite.country = '';
      // populate Buyer info with values from form
      this.setBuyerContactInfo();
    }
    // console.log(this.venodorInvite);
  }

  countBuyers(type) {
    if (type) {
      let buyersOfType = this.buyerData.filter(b => b.VendorType && b.VendorType === type);
      if (buyersOfType && buyersOfType.length > 0) {
        return buyersOfType.length;
      }
    }
    return 0;
  }


  canSubmit(form) {
    if (form.valid) {
      return true;
    }
    return false;
  }

  setBuyerContactInfo() {
    if (this.venodorInvite.vendorType === 'PlaceHolder-Domestic' || this.venodorInvite.vendorType === 'PlaceHolder-Import') {
      this.venodorInvite.buyersVendorContactFirstName = this.venodorInvite.firstName;
      this.venodorInvite.buyersVendorContactLastName = this.venodorInvite.lastName;
      this.venodorInvite.buyersVendorContactEmail = this.venodorInvite.email.toLowerCase();
    }
  }

  validateDate(form) {
    // console.log('validating date');
    if (moment(this.venodorInvite.expectedOrderDate).isBefore(this.today)) {
      form.controls['expectedOrderDate'].setErrors({'incorrect': true});
      form.controls['expectedOrderDate'].setCustomValidity('Date must not be in the past');
    } else {
      form.controls['expectedOrderDate'].setErrors(null);
      form.controls['expectedOrderDate'].setCustomValidity('');
    }
    // console.log(form.controls['expectedOrderDate']);
  }

  onSubmit(form: NgForm) {
    // attempt to refresh token
    this.users.getToken().then(res => {
      // if success, run submit function with new token
      // console.log('new token: ' + res);
      this.submitForm(form);
      console.log ('submitting form');
    },
    error => {
      // if fail, show user logout/login message
      console.log('Error refreshing token', error);
      this.modalContent.body = 'There was a problem validating your request. Please click "OK" to logout and log in again to retry this request.';
      this.modalError.show();
      this.ga.sendEvent('Vendor Invite', 'Token Error', 'Failed to submit request from ' + this.buyerData[this.venodorInvite.buyer].BuyerID ,{'time_stamp':Date.now(), 'error_msg':error.message});
    });
  }


  submitForm(form: NgForm) {
    console.log('in onSubmit:' , form.valid);
    const startTime = Date.now();
    this.buyerDetails = [];
    // this.buyerDetails = this.buyerData.filter(s => s.BuyerID === this.venodorInvite.buyer);
    this.buyerDetails = this.buyerData[this.venodorInvite.buyer];
    
      this.venodorInvite.buyerEmail = this.buyerDetails.Email.toLowerCase();
      this.venodorInvite.buyer = this.buyerDetails.BuyerID;
    
    // console.log(this.buyerDetails,this.venodorInvite.buyerEmail, this.venodorInvite.buyer);
    this.venodorInvite.email=this.venodorInvite.email.toLowerCase();
    if(form.valid) {
      this.userValue = this.venodorInvite.email;
      // console.log(this.venodorInvite.buyerCompany);
      // console.log(this.venodorInvite.vendorNumber);
      if(this.venodorInvite.buyerCompany=='TSC' && this.venodorInvite.vendorNumber!==undefined) {
          this.venodorInvite.isPlaceHolderVendor = "Y";
        }
        else {
          this.venodorInvite.isPlaceHolderVendor = "N";
        }
      if (this.venodorInvite.vendorType == 'Domestic') {
        this.venodorInvite.isVendorSupportFunds = "";
        this.venodorInvite.isRTVExceptions = "";
        this.venodorInvite.sourcingAgent = "";
        this.venodorInvite.isExclusiveBrands = "";
        if (this.venodorInvite.isVendorVMI == 'No') {
          this.venodorInvite.orderPlacement = "";
        }
          this.venodorInvite.addressLine1 = "";
          this.venodorInvite.addressLine2 = "";
          this.venodorInvite.addressLine3 = "";
          this.venodorInvite.country = "";
          this.venodorInvite.city = "";
          this.venodorInvite.state = "";
          this.venodorInvite.zipCode = "";
          this.venodorInvite.isEDISetup = "";
        this.venodorInvite.expectedOrderDate = "";
        this.venodorInvite.freightTerms = "";
        this.venodorInvite.daysToShip = "";
        this.venodorInvite.daysToDestination = "";
        this.venodorInvite.productContentContactNumber = "";
        this.venodorInvite.productContentContactFirstName = "";
        this.venodorInvite.productContentContactMidInitial = "";
        this.venodorInvite.productContentContactLastName = "";
        this.venodorInvite.productContentContactEmail = "";
        this.venodorInvite.buyersVendorContactFirstName = "";
        this.venodorInvite.buyersVendorContactMidInitial = "";
        this.venodorInvite.buyersVendorContactLastName = "";
        this.venodorInvite.buyersVendorContactNumber = "";
        this.venodorInvite.buyersVendorContactEmail = "";
        this.venodorInvite.tscReceiving = "";
        this.venodorInvite.deliveryTime = "";
      } else if (this.venodorInvite.vendorType == 'Dropship') {
        this.venodorInvite.isScanBased = "";
        this.venodorInvite.isTestVendor = "";
        this.venodorInvite.isVendorVMI = "";
        this.venodorInvite.orderPlacement = "";
        this.venodorInvite.sourcingAgent = "";
        this.venodorInvite.isExclusiveBrands = "";
        this.venodorInvite.isVendorVMI = "";
        this.venodorInvite.expectedOrderDate = "";
          this.venodorInvite.addressLine1 = "";
          this.venodorInvite.addressLine2 = "";
          this.venodorInvite.addressLine3 = "";
          this.venodorInvite.country = "";
          this.venodorInvite.city = "";
          this.venodorInvite.state = "";
          this.venodorInvite.zipCode = "";
          this.venodorInvite.isEDISetup = "Y";
        this.venodorInvite.freightTerms = "";
        this.venodorInvite.daysToShip = "";
        this.venodorInvite.daysToDestination = "";
        this.venodorInvite.productContentContactNumber = "";
        this.venodorInvite.productContentContactFirstName = "";
        this.venodorInvite.productContentContactMidInitial = "";
        this.venodorInvite.productContentContactLastName = "";
        this.venodorInvite.productContentContactEmail = "";
        this.venodorInvite.buyersVendorContactFirstName = "";
        this.venodorInvite.buyersVendorContactMidInitial = "";
        this.venodorInvite.buyersVendorContactLastName = "";
        this.venodorInvite.buyersVendorContactNumber = "";
        this.venodorInvite.buyersVendorContactEmail = "";
        this.venodorInvite.tscReceiving = "";
        this.venodorInvite.deliveryTime = "";
      } else if (this.venodorInvite.vendorType == 'Import') {
        this.venodorInvite.isScanBased = "";
        this.venodorInvite.isTestVendor = "";
        this.venodorInvite.isVendorVMI = "";
        this.venodorInvite.orderPlacement = "";
        this.venodorInvite.isVendorVMI = "";
        this.venodorInvite.isEDISetup = "";
        this.venodorInvite.isVendorSupportFunds = "";
        this.venodorInvite.isRTVExceptions = "";
        this.venodorInvite.expectedOrderDate = "";
          this.venodorInvite.addressLine1 = "";
          this.venodorInvite.addressLine2 = "";
          this.venodorInvite.addressLine3 = "";
          this.venodorInvite.country = "";
          this.venodorInvite.city = "";
          this.venodorInvite.state = "";
          this.venodorInvite.zipCode = "";
          this.venodorInvite.isEDISetup = "";
        this.venodorInvite.freightTerms = "";
        this.venodorInvite.daysToShip = "";
        this.venodorInvite.daysToDestination = "";
        this.venodorInvite.productContentContactNumber = "";
        this.venodorInvite.productContentContactFirstName = "";
        this.venodorInvite.productContentContactMidInitial = "";
        this.venodorInvite.productContentContactLastName = "";
        this.venodorInvite.productContentContactEmail = "";
        this.venodorInvite.buyersVendorContactFirstName = "";
        this.venodorInvite.buyersVendorContactMidInitial = "";
        this.venodorInvite.buyersVendorContactLastName = "";
        this.venodorInvite.buyersVendorContactNumber = "";
        this.venodorInvite.buyersVendorContactEmail = "";
        this.venodorInvite.tscReceiving = "";
        this.venodorInvite.deliveryTime = "";
      } else if (this.venodorInvite.vendorType == 'Hay Forage' || this.venodorInvite.vendorType == 'Hay') {
        this.venodorInvite.isScanBased = "";
        this.venodorInvite.isTestVendor = "";
        this.venodorInvite.isVendorVMI = "";
        this.venodorInvite.isVendorSupportFunds = "";
        this.venodorInvite.isRTVExceptions = "";
        this.venodorInvite.sourcingAgent = "";
        this.venodorInvite.isExclusiveBrands = "";
        this.venodorInvite.expectedOrderDate = "";
        this.venodorInvite.addressLine1 = "";
        this.venodorInvite.addressLine2 = "";
        this.venodorInvite.addressLine3 = "";
        this.venodorInvite.country = "";
        this.venodorInvite.city = "";
        this.venodorInvite.state = "";
        this.venodorInvite.zipCode = "";
        this.venodorInvite.freightTerms = "";
        this.venodorInvite.daysToShip = "";
        this.venodorInvite.daysToDestination = "";
        this.venodorInvite.productContentContactNumber = "";
        this.venodorInvite.productContentContactFirstName = "";
        this.venodorInvite.productContentContactMidInitial = "";
        this.venodorInvite.productContentContactLastName = "";
        this.venodorInvite.productContentContactEmail = "";
        this.venodorInvite.buyersVendorContactFirstName = "";
        this.venodorInvite.buyersVendorContactMidInitial = "";
        this.venodorInvite.buyersVendorContactLastName = "";
        this.venodorInvite.buyersVendorContactNumber = "";
        this.venodorInvite.buyersVendorContactEmail = "";
        this.venodorInvite.tscReceiving = "";
        this.venodorInvite.deliveryTime = "";
      } else if (this.venodorInvite.vendorType == 'PlaceHolder-Dropship' || this.venodorInvite.vendorType == 'PlaceHolder-Domestic') {
        this.venodorInvite.isScanBased = "";
        this.venodorInvite.isTestVendor = "";
        this.venodorInvite.isVendorVMI = "";
        this.venodorInvite.orderPlacement = "";
        this.venodorInvite.isVendorSupportFunds = "";
        this.venodorInvite.isEDISetup = "";
        this.venodorInvite.isRTVExceptions = "";
        this.venodorInvite.sourcingAgent = "";
        this.venodorInvite.isExclusiveBrands = "";
        const datePipe = new DatePipe("en-US");
        this.venodorInvite.expectedOrderDate = datePipe.transform(this.venodorInvite.expectedOrderDate, 'dd-MMM-yyyy');

      } else if (this.venodorInvite.vendorType == 'PlaceHolder-Import') {
        this.venodorInvite.isScanBased = "";
        this.venodorInvite.isTestVendor = "";
        this.venodorInvite.isVendorVMI = "";
        this.venodorInvite.orderPlacement = "";
        this.venodorInvite.isVendorSupportFunds = "";
        this.venodorInvite.isEDISetup = "";
        this.venodorInvite.isRTVExceptions = "";
        const datePipe = new DatePipe("en-US");
        this.venodorInvite.expectedOrderDate = datePipe.transform(this.venodorInvite.expectedOrderDate, 'dd-MMM-yyyy');

      } else {
        this.venodorInvite.isPlaceHolderVendor="";
        this.venodorInvite.vendorNumber="";
        this.venodorInvite.isTestVendor = "";
        this.venodorInvite.isVendorVMI = "";
          this.venodorInvite.addressLine1 = "";
          this.venodorInvite.addressLine2 = "";
          this.venodorInvite.addressLine3 = "";
          this.venodorInvite.country = "";
          this.venodorInvite.city = "";
          this.venodorInvite.state = "";
          this.venodorInvite.zipCode = "";
          if (this.venodorInvite.isScanBased === 'Y') {
            this.venodorInvite.isEDISetup = "Y";
          } else if (this.venodorInvite.orderPlacement === 'EDI Setup' || this.venodorInvite.orderPlacement === 'Temporary Fax PO') {
            this.venodorInvite.isEDISetup = "Y";
          } else {
            this.venodorInvite.isEDISetup = "";
          }
        this.venodorInvite.isVendorVMI = "";
        this.venodorInvite.isVendorSupportFunds = "";
        this.venodorInvite.isRTVExceptions = "";
        this.venodorInvite.sourcingAgent = "";
        this.venodorInvite.isExclusiveBrands = "";
        this.venodorInvite.expectedOrderDate = "";
        this.venodorInvite.freightTerms = "";
        this.venodorInvite.daysToShip = "";
        this.venodorInvite.daysToDestination = "";
        this.venodorInvite.productContentContactNumber = "";
        this.venodorInvite.productContentContactFirstName = "";
        this.venodorInvite.productContentContactMidInitial = "";
        this.venodorInvite.productContentContactLastName = "";
        this.venodorInvite.productContentContactEmail = "";
        this.venodorInvite.buyersVendorContactFirstName = "";
        this.venodorInvite.buyersVendorContactMidInitial = "";
        this.venodorInvite.buyersVendorContactLastName = "";
        this.venodorInvite.buyersVendorContactNumber = "";
        this.venodorInvite.buyersVendorContactEmail = "";
        this.venodorInvite.tscReceiving = "";
        this.venodorInvite.deliveryTime = "";
        this.venodorInvite.expectedOrderDate = "";

      }
      /*
      this.selectedUser.firstName = this.venodorInvite.firstName;
      this.selectedUser.lastName = this.venodorInvite.lastName;
      this.selectedUser.groupName = "Guest";
      this.selectedUser.roleName = "Guest";
      this.selectedUser.createDate = new Date() + "";
      this.selectedUser.vendorName = this.venodorInvite.company;
      this.selectedUser.vendorNumber = this.venodorInvite.vendorNumber + "";
      this.selectedUser.userName = this.venodorInvite.email
      this.selectedUser.vendorType = this.venodorInvite.vendorType;
      this.submitUser(this.selectedUser);
      */
     // NSC-53
     if(!this.venodorInvite.isBuyerOverride){
      this.venodorInvite.isBuyerOverride = 'N';
      this.venodorInvite.buyerOverrideReason = '';
     }
      console.log(this.venodorInvite);
      // const safeEmail = this.venodorInvite.email.replace('@','(at)').replace('.','(dot)');
      const safeEmail = this.venodorInvite.email.split('@')[0];
     this.inviteService.postVendorInviteForm(this.venodorInvite).subscribe(data => {
          console.log(JSON.stringify(data));
          let value=JSON.stringify(data);
          value=value.replace("_body","body");
          const responseTime = Date.now() - startTime;

          // scroll to top
          window.scrollTo({ top: 0, behavior: 'smooth' });
          // console.log('should scroll to top now...');

          let parsed = JSON.parse(value);
          if(parsed["body"]==='Success'){
            this.showMsg="User has been added successfully";
            this.ga.sendEvent('Vendor Invite', 'Invite sent to ' + safeEmail, 'Success', {'load_time':responseTime});
          }else {
            this.showMsg = parsed["body"];
            this.ga.sendEvent('Vendor Invite', 'Failed to invite ' + safeEmail, this.showMsg, {'time_stamp':Date.now(), 'error_msg':this.showMsg});
          }
        }, error => {
        this.showMsg = "Failed";
        const responseTime = Date.now() - startTime;
        this.ga.sendEvent('Vendor Invite', 'Failed to invite ' + safeEmail, 'Failed',{'time_stamp':Date.now(), 'load_time':responseTime, 'err_msg':error.message});
        }
      );
    }
    form.resetForm();
    this.venodorInvite = {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      vendorType: 'Domestic',
    };
    if(this.users.getUserRole() === 'TSC_Buyer'){
      this.venodorInvite.buyerCompany='TSC';
    }else if(this.users.getUserRole() === 'PTS_Buyer'){
      this.venodorInvite.buyerCompany='PETSENSE';
    }
    this.onboarindReasonText = '';
    this.onboardingFormSubmit = true;
  }

  resetFormFields(){
    this.formRef.resetForm();
    this.venodorInvite = {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      vendorType: 'Domestic',
    };
    if(this.users.getUserRole() === 'TSC_Buyer'){
      this.venodorInvite.buyerCompany='TSC';
    }else if(this.users.getUserRole() === 'PTS_Buyer'){
      this.venodorInvite.buyerCompany='PETSENSE';
    }
    this.onboarindReasonText = '';
    this.onboardingFormSubmit = true;
  }


  submitUser(user){
    console.log('adding user', user);

    let req;
    // handle multiple roles
    if (user.roleName && Array.isArray(user.roleName)) {
      user.roleName = user.roleName.join(',');
    }
    if (user.roleName.indexOf('VendorAdmin') > -1) {
      user.roleName = 'VendorAdmin'; // remove unnecessary roles
      user.groupName = 'VENDOR_ADMIN';
    }else if (user.roleName.indexOf('Guest') > -1) {
      user.roleName = 'Guest'; // remove unnecessary roles
      user.groupName = 'GUEST';
    } else {
      // not vendor admin
      user.groupName = 'Vendors';
    }
    // new user
    // console.log('creating new user');
    this.modalContent.body = 'User ' + user.userName + ' has been added.';
    user.createDate = moment().utc().format('M/DD/YYYY, h:mm:ss A');
    // parse to new user request object

    this.users.getUserDetails(user).subscribe(
      data => {
        // console.log(data);
        if(data && data.json() && data.json().length > 0) {
          console.log('User already exists');
          this.showMsg="User already exists";
          const safeUser = user.userName.split('@')[0];
          this.ga.sendEvent('Vendor Invite', 'User ' + safeUser + ' already exists', JSON.stringify(data),{'time_stamp':Date.now()});
        } else {
          req = new newUserRequest();
          req.loggedUser = this.users.loggedInUser.userName;
          req.userName = user.userName.toLowerCase();
          req.email = user.userName.toLowerCase();
          req.firstName = user.firstName;
          req.lastName = user.lastName;
          req.roleName = user.roleName;
          req.vendorName = user.vendorName;
          req.vendorNumber = this.users.loggedInUser.vendorNumber;
          req.createDate = user.createDate;
          req.createBy = this.users.loggedInUser.userName;
          req.groupName = user.groupName;
          req.roleName = user.roleName;
          req.vendorType = user.vendorType;
          // console.log('user request', req);
          // const safeUserName = req.userName.replace('@','(at)').replace('.','(dot)');
          const safeUserName = req.userName.split('@')[0];
          this.users.editVendorUser(req).subscribe(
            data => {
              console.log(data);
              this.addNewUser();
              this.ga.sendEvent('Vendor Invite', 'Add User ' + safeUserName, JSON.stringify(data),{'time_stamp':Date.now()});
            }, error => {
              console.log(error);
              this.showMsg= "User has been added successfully";
              this.ga.sendEvent('Vendor Invite', 'Error adding User ' + safeUserName, error.message + '||' + JSON.stringify(req),{'time_stamp':Date.now(), 'error_msg':error.message});
            }
          );
        }
      }
    );
  }

  private extractData(res) {
    let body = res.json();  // If response is a JSON use json()
    if (body) {
      return body.data|| body;
    } else {
      return {};
    }
  }


  getParentVendorType(vendorNumber){
      let vendorId = vendorNumber;
      const json = {
        'VendorID': vendorId
      }
     return this.api.requestAddVendor(json);
  }

  setAddVendorType(form){
    //this.venodorInvite.isAdditionalVendor="N";
    this.vendorData = [];
    if(this.venodorInvite.vendorNumber.length === 6) {
      this.getParentVendorType(this.venodorInvite.vendorNumber).subscribe((data: any) => {
        // console.log(data);
        this.vendorData = data.VendorData;
        if (!this.vendorData || !this.vendorData.length) {
          form.controls['vendorNumber'].setErrors({'incorrect': true});
          this.vendorNumberError = true;
        } else if (this.vendorData || this.vendorData.length) {
          form.controls['vendorNumber'].setErrors({'incorrect': true});
          this.vendorNumberError = true;
          let placeHolder = this.vendorData.filter(
            vendorDetails => vendorDetails.VendorType.includes('PlaceHolder'));
          for (let vendor of placeHolder) {
            form.controls['vendorNumber'].setErrors(null);
            this.vendorNumberError = false;
            if(vendor.VendorType === 'PlaceHolder-Domestic'){
              this.placeHolderDomestic=true;
              this.placeHolderImport=false;
              this.venodorInvite.vendorType='Domestic';
            }else{
              this.placeHolderImport=true;
              this.placeHolderDomestic=false;
              this.venodorInvite.vendorType='Import';
            }
            }
        }
        else{
          this.vendorNumberError = false;
        }
      });
    }
  }

  getBuyerInfo(){
    this.api.getBuyerDetails().subscribe((data: any) => {
      this.buyerData = data.Data;
    });
  }

  setFPVValue(){
    this.venodorInvite.tscReceiving = "2"; //default value
    this.venodorInvite.daysToDestination = "";
  }

  setFCTValue(){
    this.venodorInvite.tscReceiving = "2"; //default value
    this.venodorInvite.daysToDestination = "2";
  }

  setFOBValue(){
    this.venodorInvite.tscReceiving = "2"; //default value
    this.venodorInvite.daysToDestination = "45";
  }

  logout() {
    this.modalError.hide();
    this.users.logout();
  }
  // NSC-53 changes starts
  vendorNameChange(vendorName:string){
    if(vendorName && vendorName.length>0){
      this.isLoading = true;
      this.onboarindReasonText = '';
      this.checkForDuplicateVendorName(vendorName).subscribe( (res:CompanyNameResponse)=>{
        console.log("RESPONSE:", res);
        if(res && res.ReasonCode){
          const matchedReasconCode:string = res.ReasonCode;
          if(matchedReasconCode === 'No Match'){
            // It means there were no match with existing company name/Vendor name so good to proceed
            this.onboardingFormSubmit = true;
            this.venodorInvite.isBuyerOverride ='N';
            this.venodorInvite.buyerOverrideReason ='';

          }else {
            // It can be with 100% match or 80% match
            let displayMessage='';
            let popupData:PopupDialogData;
            const splitReasonCode:string[] = matchedReasconCode.split(" ");
            if(splitReasonCode[0]==='100%'){
              // 100% match , 
             
             // res.IsVendorActive = 'N';
               // Check for vendor is active
             // if(res.IsVendorActive && res.IsVendorActive === 'Y'){ // original one
             if(res.IsVendorActive && res.IsVendorActive === 'Y' || (res.IsVendorActive && res.IsVendorActive === 'N' && res.OnboardingStatus && res.ReasonCode==='100% Match In-Progress Vendor')){ 
            
                // This is for 100% match with Active vendor
                // Show the below message  to the Buyer and not to allow to submit the form
                // const vendorInfo= res.IsVendorActive === 'Y' ? 'An Active ':'An InActive ';
                const vendorInfo= 'A Vendor ';
                 displayMessage="<p>"+vendorInfo+ "already exists with the same name <b>"+res.CompanyName+"</b>. Please review and work with Vendor Master Data if you have any additional concerns.</p>";
                
               popupData ={
                title: "Notification",
                message: displayMessage
              }
  
              }else if(res.IsVendorActive && res.IsVendorActive === 'N'){
                //This is for 100% match with In-active vendor
                //Message:A vendor already exists with a similar name of [Vendor Name], are you sure you want to proceed [y/n] 
                 displayMessage="An Inactive Vendor already exists with a similar name <b>"+res.CompanyName+"</b>. Are you sure you want to proceed(YES/NO)?";
                popupData={
                  title: "Continue?",
                  message: displayMessage,
                  showReasonText:true                 
                }
  
              }
  
              let dialogServiceRef = this.poupDialogService.openDialog(popupData);
              dialogServiceRef.afterClosed().subscribe((result:PopupDialogResponse) =>{
                  this.onboardingFormSubmit = result.continueOnborading;
                  if(result.reasonText && result.reasonText.length >0){
                    this.onboarindReasonText = result.reasonText;
                  }
                  if(this.onboardingFormSubmit){
                    this.venodorInvite.isBuyerOverride = 'Y';
                   this.venodorInvite.buyerOverrideReason = this.onboarindReasonText;
                  }else {
                    // Focus on VendorName Input Text
                    this.resetFormFields();
                    // this.vendorNameInputTextRef.nativeElement.value='';
                    // this.vendorNameInputTextRef.nativeElement.focus();
                  }
                  
                 });
  
            }// end of 100% match condition

             // 80% match
          if(splitReasonCode[0] ==='80%'){

           // const vendorInfo= res.IsVendorActive ?res.IsVendorActive === 'Y' ? 'An Active Vendor ':'An InActive Vendor ': 'A Vendor '
           const vendorInfo= res.IsVendorActive ?res.IsVendorActive === 'Y' ? 'A Vendor ':'An Inactive Vendor ': 'A Vendor '

            // if the vendor already presents with 80% match and wheather inprogress/active vendor then
            displayMessage=vendorInfo+"already exists with a similar name <b>"+res.CompanyName+"</b>. Are you sure you want to proceed(YES/NO)?";
              
            popupData={
             title: "Continue?",
             message: displayMessage,
             showReasonText:true
            
           };

           let dialogServiceRef = this.poupDialogService.openDialog(popupData);
           dialogServiceRef.afterClosed().subscribe((result:PopupDialogResponse) =>{

               console.log("Response from dialog close event:",result);
               this.onboardingFormSubmit = result.continueOnborading;
               if(result.reasonText && result.reasonText.length >0){
                 this.onboarindReasonText = result.reasonText;
               }
               if(this.onboardingFormSubmit){
                this.venodorInvite.isBuyerOverride = 'Y';
                this.venodorInvite.buyerOverrideReason = this.onboarindReasonText;

              }else{
                  // Focus on VendorName Input text
                  this.resetFormFields();
                  // this.vendorNameInputTextRef.nativeElement.value='';
                  // this.vendorNameInputTextRef.nativeElement.focus();
                 }
               
              });

          } // end of 80% match

          }
          
        }

        this.isLoading = false;
      },
      (error)=>{
        this.isLoading = false;
        console.log("Error in duplicate vendor check API..", error);

      }
      )

    }
    
  }
  checkForDuplicateVendorName(vendorName:any): Observable<any>{
    const endPoint = "companynamecheck";
    let request:CompanyNameRequest={
      CompanyName:vendorName.trim()
    }
    
   return  this.api.duplicateVendorCheckCall(endPoint,request).pipe( map( (data:CompanyNameResponse) =>{
      return data
    }));

  }
  //NSC-53 changes ends

}
