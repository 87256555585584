<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <!-- <h2>Manage Vendor Invites</h2> -->
      <div class="container vendor-invites section blue">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="section-title">Duplicate Vendor Invite Approval</h3>
          </div>
        </div>
        <div class="row headers">
          <div class="col-sm-3" (click)="sortBy('CompanyName', $event)">Vendor Name <span class="sort"></span></div>
          <div class="col-sm-3" (click)="sortBy('Email', $event)">User Email Address <span class="sort"></span></div>
          <div class="col-sm-2" (click)="sortBy('VendorType', $event)">Type <span class="sort"></span></div>
          <div class="col-sm-1" (click)="sortBy('BuyerID', $event)">Buyer ID <span class="sort"></span></div>
          <div class="col-sm-3">&nbsp;</div>
        </div>
        <div class="row data" *ngFor="let invite of vendorInvites; let y = index">
          <div class="col-sm-3">{{ invite.CompanyName }}</div>
          <div class="col-sm-3">{{ invite.Email }}</div>
          <div class="col-sm-2">{{ invite.VendorType }}</div>
          <div class="col-sm-1">{{ invite.BuyerID }}</div>
          <div class="col-sm-3">
            <button (click)="showDetails(y)" class="btn btn-sm btn-outline-primary">Show Details</button>&nbsp;
            <button (click)="showDetails(y,'accept')" class="btn btn-sm btn-success">Accept</button>&nbsp;
            <button (click)="showDetails(y,'reject')" class="btn btn-sm btn-danger">Reject</button>
          </div>
        </div>
        <div class="row" *ngIf="isLoading('duplicates')">
          <div class="col-sm-12 text-center">
            <mat-spinner [diameter]="16" style="float:left;margin-right:.5em;"></mat-spinner> Loading...
          </div>
        </div>
        <div class="row" *ngIf="!isLoading('duplicates') && vendorInvites.length==0">
          <div class="col-sm-12 text-danger text-center">No duplicate invites found.</div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal class="vendor-invite-details" #modalDetails>

  <div class="app-modal-header" [class]="'alert alert-' + modalContent.class">
    {{ modalContent.header }}
    <a class="close" (click)="hideDetails(modalDetails)">&times;</a>
  </div>

  <div class="app-modal-body">

    {{ modalContent.body }}

    <div class="row">
      <div class="col-sm-10" *ngIf="!editingName">
        <h2><span class="vendor-name">{{ displayRecord?.CompanyName }} </span> 
          <span *ngIf="modalType!='reject'" class="badge badge-pill badge-light edit-name" (click)="editCompanyName()">Modify Vendor Name</span></h2>
      </div>
      <div class="col-sm-10 form-inline" *ngIf="editingName">
        <div class="form-group">
          New Vendor Name:&nbsp;<input type="text" class="form-control" [value]="displayRecord.CompanyName" size="72" (blur)="finishEditingName($event)" (keyup.enter)="finishEditingName($event)"> <span class="btn btn-outline-info edit-name" (click)="editCompanyName('stop')">Finish Editing</span>
          <!-- &nbsp;<span class="small text-danger">validation error...</span> -->
        </div>
      </div>
      <div class="col-sm-2"><span *ngIf="!editingName && displayRecord.isAdditionalVendor && displayRecord.isAdditionalVendor == 'Y'"class="badge badge-pill badge-info">Additional Vendor</span> </div>
    </div>

    <!-- automated list of properties -->
    <!-- 
    <div class="row"> 
      <ng-container *ngFor="let item of displayRecord | keyvalue">
        <div *ngIf="listKey(item)" class="col-sm-6"><span class="font-weight-lighter">{{ item.key }}:&nbsp;</span>{{ item.value}}</div>
      </ng-container>
    </div>
    -->

    <div class="row">
      <div class="col-sm-4"><span class="font-weight-lighter">Email: </span>{{ displayRecord.Email }}</div>
      <div class="col-sm-4"><span class="font-weight-lighter">Name: </span>{{ displayRecord.FirstName }} {{ displayRecord.LastName }} </div>
      <div class="col-sm-4"><span class="font-weight-lighter">Vendor Type: </span>{{ displayRecord.VendorType }}</div>
      
      <div class="col-sm-4"><span class="font-weight-lighter">Placeholder: </span>{{ displayRecord.isPlaceHolderVendor }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">Buyer ID: </span>{{ displayRecord.BuyerID }}</div>

    </div>

    <div class="row" *ngIf="displayRecord.VendorType == 'Domestic'">
      <div class="col-sm-4"><span class="font-weight-lighter">Scan-Based: </span>{{ displayRecord.isScanBased }}</div>
      <div class="col-sm-4"><span class="font-weight-lighter">Test Vendor: </span>{{ displayRecord.isTestVendor }}</div>
      <div class="col-sm-4"><span class="font-weight-lighter">VMI: </span>{{ displayRecord.isVendorVMI }}</div>
    </div>
    <div class="row" *ngIf="displayRecord.VendorType =='Dropship'">
      <div class="col-sm-12"><span class="font-weight-lighter">Does the vendor have online RTV exceptions? </span>{{ displayRecord.isRTVExceptions}}</div>
    </div>
    <div class="row" *ngIf="displayRecord.VendorType =='Import'">
      <div class="col-sm-12"><span class="font-weight-lighter">Sourcing Agent: </span>{{ displayRecord.sourcingAgent || 'No Agent' }}</div>
    </div>
    <div class="row" *ngIf="displayRecord.VendorType =='Hay Forage' || displayRecord.VendorType == 'Hay'">
      <div class="col-sm-4"><span class="font-weight-lighter">Does this need to be setup for EDI? </span>{{ displayRecord.isEDISetup }}</div>
    </div>
    <div class="row" *ngIf="displayRecord.VendorType =='Placeholder-Domestic'">
      <div class="col-sm-3"><span class="font-weight-lighter">Freight Terms: </span>{{ displayRecord.freightTerms }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">Days to Ship: </span>{{ displayRecord.daysToShip }}</div>
      <div class="col-sm-3"><span class="font-weight-lighter">Days to Destination: </span>{{ displayRecord.daysToDestination }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">Days to TSC: </span>{{ displayRecord.tscReceiving }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">Lead Time: </span>{{ displayRecord.deliveryTime }}</div>

      <div class="col-sm-6"><span class="font-weight-lighter">Product Content Contact: </span>{{ displayRecord.productContentContactFirstName }} {{ displayRecord.productContentContactLastName }} ({{ displayRecord.productContentContactEmail }})</div>
      <div class="col-sm-6"><span class="font-weight-lighter">Buyer's Vendor Contact: </span>{{ displayRecord.buyersVendorContactFirstName }} {{ displayRecord.buyersVendorContactLastName }} ({{ displayRecord.buyersVendorContactEmail}})</div>

      <div class="col-sm-8"><span class="font-weight-lighter">Address: </span>{{ displayRecord.addressLine1 }}</div>
      <div class="col-sm-4"><span class="font-weight-lighter"> </span>{{ displayRecord.addressLine2 }}</div>
      <div class="col-sm-4"><span class="font-weight-lighter">City: </span>{{ displayRecord.city }}</div>
      <div class="col-sm-3"><span class="font-weight-lighter">State: </span>{{ displayRecord.state }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">ZIP: </span>{{ displayRecord.zipCode }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">Country: </span>{{ displayRecord.country }}</div>
      
    </div>
    <div class="row" *ngIf="displayRecord.VendorType =='Placeholder-Import'">
      <div class="col-sm-4"><span class="font-weight-lighter">Sourcing Agent: </span>{{ displayRecord.sourcingAgent || 'No Agent' }}</div>
      <div class="col-sm-4"><span class="font-weight-lighter">Exclusive Brands: </span>{{ displayRecord.isExclusiveBrands }}</div>
      <div class="col-sm-4"><span class="font-weight-lighter">Expected Order Date: </span>{{ displayRecord.expectedOrderDate }}</div>

      <div class="col-sm-3"><span class="font-weight-lighter">Freight Terms: </span>{{ displayRecord.freightTerms }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">Days to Ship: </span>{{ displayRecord.daysToShip }}</div>
      <div class="col-sm-3"><span class="font-weight-lighter">Days to Destination: </span>{{ displayRecord.daysToDestination }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">Days to TSC: </span>{{ displayRecord.tscReceiving }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">Lead Time: </span>{{ displayRecord.deliveryTime }}</div>


      <div class="col-sm-6"><span class="font-weight-lighter">Product Content Contact: </span>{{ displayRecord.productContentContactFirstName }} {{ displayRecord.productContentContactLastName }} ({{ displayRecord.productContentContactEmail }})</div>
      <div class="col-sm-6"><span class="font-weight-lighter">Buyer's Vendor Contact: </span>{{ displayRecord.buyersVendorContactFirstName }} {{ displayRecord.buyersVendorContactLastName }} ({{ displayRecord.buyersVendorContactEmail}})</div>

      <div class="col-sm-8"><span class="font-weight-lighter">Address: </span>{{ displayRecord.addressLine1 }}</div>
      <div class="col-sm-4"><span class="font-weight-lighter"> </span>{{ displayRecord.addressLine2 }}</div>
      <div class="col-sm-4"><span class="font-weight-lighter">City: </span>{{ displayRecord.city }}</div>
      <div class="col-sm-3"><span class="font-weight-lighter">State: </span>{{ displayRecord.state }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">ZIP: </span>{{ displayRecord.zipCode }}</div>
      <div class="col-sm-2"><span class="font-weight-lighter">Country: </span>{{ displayRecord.country }}</div>

    </div>

    <div class="row">
      <div class="col-sm-12"><span class="font-weight-lighter">Reason: </span>{{ displayRecord.BuyerOverrideReason }}</div>
    </div>

    <div class="row" *ngIf="displayRecord.isAdditionalVendor && displayRecord.isAdditionalVendor == 'Y'">
      <!-- NSC-11 edit shipping address -->
      <ng-container *ngIf="!editingAddress">
        <div class="col-sm-8"><span class="font-weight-lighter">Address: </span>{{ displayRecord.addressLine1 }}</div>
        <div class="col-sm-4"><span class="font-weight-lighter"> </span>{{ displayRecord.addressLine2 }}</div>
        <div class="col-sm-4"><span class="font-weight-lighter">City: </span>{{ displayRecord.city }}</div>
        <div class="col-sm-3"><span class="font-weight-lighter">State: </span>{{ displayRecord.state }}</div>
        <div class="col-sm-2"><span class="font-weight-lighter">ZIP: </span>{{ displayRecord.zipCode }}</div>
        <div class="col-sm-2"><span class="font-weight-lighter">Country: </span>{{ displayRecord.country }}</div>
        <div class="col-sm-1"><span *ngIf="modalType!='reject'" class="badge badge-pill badge-light edit-name" (click)="editAddress()">Edit</span></div>
      </ng-container>

      <div class="col-sm-12" *ngIf="editingAddress">
        <div class="container edit-address"><div class="close" (click)="stopEditing()">&times;</div>
          <div class="row">
            <div class="col-sm-12"><label class="tiny" for="addressLine1">Address 1 </label><input class="form-control" name="addressLine1" type="text" [(ngModel)]="newAddress.addressLine1"></div>
            <div class="col-sm-12"><label class="tiny" for="addressLine2">Address 2 </label><input class="form-control" name="addressLine2" type="text" [(ngModel)]="newAddress.addressLine2"></div>
            <div class="col-sm-6"><label for="country">Country </label>
              <!-- <input class="form-control" name="country" type="text" [(ngModel)]="newAddress.country"> -->
              <select  class="form-control" name="country" [ngModel]="newAddress.country" (change)="setCountry($event.target.value)"> <!-- [(ngModel)]="newAddress.country" formControlName="country"  -->
                <option *ngFor="let c of countryList" [value]="c.NAME">{{c.ABBR}} {{c.NAME}}</option>
              </select>
            </div>
            <div class="col-sm-6">
              <label for="state">State </label>
              <!-- <input class="form-control" name="state" type="text" [(ngModel)]="newAddress.state"> -->
              <select  class="form-control" [disabled]="!stateList || stateList.length < 2" name="state" [(ngModel)]="newAddress.state"> <!-- formControlName="state" -->
                <option *ngFor="let s of stateList" [value]="s.NAME">{{s.NAME}}</option>
              </select>
            </div>
            <div class="col-sm-6"><label class="tiny" for="city">City </label><input class="form-control" name="city" type="text" [(ngModel)]="newAddress.city"></div>
            <div class="col-sm-6"><label for="zip">ZIP </label><input class="form-control" name="zip" type="text" [(ngModel)]="newAddress.zipCode"></div>
              
          </div>
          <div class="row">
            <div class="col-sm-12 text-center"><span class="btn btn-outline-dark" (click)="finishEditingAddress()">Finish Editing</span></div>
          </div> 
        </div> 
      </div>

    </div>

    <div class="matching-vendors">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="title">Matching Existing Vendors</h3>
          <div class="container matching-vendor-list">
            <div class="row headers">
              <div class="col-sm-2">Vendor ID</div>
              <div class="col-sm-6">Company Name</div>
              <div class="col-sm-4">Status</div>
            </div>
            <div class="row data" *ngFor="let d of displayRecord.DuplicateVendors">
              <div class="col-sm-1">{{ d.VendorID }}</div>
              <div class="col-sm-1 status-column">
                <span *ngIf="d.IsVendorActive === 'Y'" class="badge badge-success badge-pill">Active</span>
                <span *ngIf="d.IsVendorActive !== 'Y'" class="badge badge-light badge-pill">Inactive</span>
              </div>
              <div class="col-sm-6">{{ d.CompanyName }}</div>
              <div class="col-sm-4">{{ d.OnboardingStatus }}</div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div>

  <div class='app-modal-footer'>
    <div class="form-inline">
      <div class="form-group">
        <span class="saving-msg" *ngIf="isLoading('savereq')"><mat-spinner [diameter]="16" style="float:left;margin-right:.5em;"></mat-spinner></span>&nbsp;
        <label for="reasonControl">Reason:&nbsp;</label>
        <input class="form-control form-control-sm" type="text" name="reasonControl" placeholder="enter required reason..." (keyup)="updateReason($event)" [value]="displayRecord.VMDComments || ''" size="50"/>
        <span class="required">*</span>
      </div>
        &nbsp;<button type="button" (click)="validateRequest('accept', responseModal, $event)" class="btn btn-success" [disabled]="!allowSubmit()" *ngIf="modalType!='reject'">Accept</button>
        &nbsp;<button type="button" (click)="validateRequest('reject', responseModal, $event)" class="btn btn-danger" [disabled]="!allowSubmit()" *ngIf="modalType!='accept'">Reject</button>
        &nbsp;<button type="button" (click)="hideDetails(modalDetails)" class="btn btn-outline-secondary">Cancel</button>
        
    </div>
  </div>

</app-modal>

<app-modal #responseModal>
  <div class="app-modal-header" [class]="'alert alert-' + modalContent.class">

    {{ modalContent.header }}

    <a class="close" (click)="responseModal.hide()">&times;</a>
  </div>
  <div class="app-modal-body">

    {{ modalContent.body }}

    </div>
    <div class="app-modal-footer row">

      <div class="col-sm-10 small">{{ modalContent.footer }}&nbsp;</div>
      <div class="col-sm-2 text-right"><button (click)="responseModal.hide()" class="btn btn-outline-secondary">OK</button></div>

    </div>
</app-modal>
