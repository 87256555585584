import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { EventService, Event } from './../services/event.service';
import { GoogleAnalyticsService } from './../services/analytics.service';
import { DocumentService } from './../services/document.service';
import { saveAs } from 'file-saver';


export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  currentDate = moment();
  dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  weeks: CalendarDate[][] = [];
  sortedDates: CalendarDate[] = [];
  selectedEvent: Event;

  modalContent = {
    'header': '',
    'body': '',
    'footer': ''
  };

  @Input() selectedDates: CalendarDate[] = [];
  @Output() onSelectDate = new EventEmitter<CalendarDate>();

  constructor(
    public events: EventService,
    public ga: GoogleAnalyticsService,
    public docs: DocumentService,
  ) {}

  ngOnInit(): void {
    this.generateCalendar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDates &&
        changes.selectedDates.currentValue &&
        changes.selectedDates.currentValue.length  > 1) {
      // sort on date changes for better performance when range checking
      this.sortedDates = _.sortBy(changes.selectedDates.currentValue, (m: CalendarDate) => m.mDate.valueOf());
      this.generateCalendar();
    }
  }

  // date checkers

  isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  isSelected(date: moment.Moment): boolean {
    return _.findIndex(this.selectedDates, (selectedDate) => {
      return moment(date).isSame(selectedDate.mDate, 'day');
    }) > -1;
  }

  isSelectedMonth(date: moment.Moment): boolean {
    return moment(date).isSame(this.currentDate, 'month');
  }

  selectDate(date: CalendarDate): void {
    this.onSelectDate.emit(date);
  }

  showEndDate(event) {
    if (event.endDate && event.endDate !== '') {
      const r = moment(event.startDate).isSame(event.endDate, 'day');
      return !r;
    } else {
      return false;
    }


  }

  // actions from calendar

  prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.generateCalendar();
  }

  nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'months');
    this.generateCalendar();
  }

  firstMonth(): void {
    this.currentDate = moment(this.currentDate).startOf('year');
    this.generateCalendar();
  }

  lastMonth(): void {
    this.currentDate = moment(this.currentDate).endOf('year');
    this.generateCalendar();
  }

  prevYear(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'year');
    this.generateCalendar();
  }

  nextYear(): void {
    this.currentDate = moment(this.currentDate).add(1, 'year');
    this.generateCalendar();
  }

  // generate the calendar grid

  generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks: CalendarDate[][] = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
  }

  fillDates(currentMoment: moment.Moment): CalendarDate[] {
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'days');
    const start = firstDayOfGrid.date();
    return _.range(start, start + 42)
            .map((date: number): CalendarDate => {
              const d = moment(firstDayOfGrid).date(date);
              return {
                today: this.isToday(d),
                selected: this.isSelected(d),
                mDate: d,
              };
            });
  }

  showEvent(id, modal) {
    if (id === 'id') {
      console.log('showing dummy event');
    }
    console.log('id=' + id);
    this.selectedEvent = this.events.getEventList().find(e => e.id === id);
    console.log(this.selectedEvent);
    this.ga.track('/VendorPortal/events/view/' + id);
    modal.show();
  }

  closeEventDetail(modal) {
    modal.hide();
  }

  listEvent(day, event) {

      let d = moment(event.startDate);
      let e = moment(event.endDate);
      if(d.isSame(day.mDate, 'day')){
        return true;
      } else if (e && d.isBefore(day.mDate, 'day') && e.isSameOrAfter(day.mDate, 'day')) {
        return true;
      } else {
        return false;
      }

  }

  isMulti(event) {
    let d = moment(event.startDate);
    if (d.isSame(event.endDate, 'day')) {
      return false;
    } else {
      // console.log('event spans multiple days', event);
      return true;
    }
  }

  addToCalendar(event) {

    // get iCal data
    // let iCalData = 'BEGIN:VCALENDAR\nVERSION:2.0\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nUID:9832@meetingplanner.io\nDTSTART:STARTDATE\nDTEND:ENDDATE\nDTSTAMP:STARTDATE\nLOCATION: LOCATION\nSUMMARY: DESCRIPTION\nSEQUENCE:0\nTRANSP:OPAQUE\nEND:VEVENT\nEND:VCALENDAR';
    let iCalData = this.events.generateICAL(event);
    // console.log(encodeURIComponent(iCalData));

    let eventFileName = event.eventName + '.ics';

    let el = document.createElement('a');
    el.setAttribute('href', 'data:text/calendar;charset=utf8,' + encodeURIComponent(iCalData));
    el.setAttribute('download', eventFileName);
    el.style.display = 'none';
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);

    // GA event
    this.ga.sendEvent('VendorPortal', 'Add Event ' + event.eventName + ' to Outlook Calendar', 'Events');
  }

  addToGoogleCalendar(event) {
    // generate Google Calendar link
    let link = 'http://www.google.com/calendar/event?action=TEMPLATE&dates=';

    let start = moment(event.startDate).utc().format('YYYYMMDDTHHmmss') + 'Z';
    let end = moment(event.endDate).utc().format('YYYYMMDDTHHmmss') + 'Z';

    console.log(event.startDate, start);

    link = link + start + '%2F' + end + '&text=' + encodeURIComponent(event.eventName);
    if (event.eventLocation) {
      link = link + '&location=' + event.eventLocation;
    }
    if (event.eventDesc) {
      link = link + '&details=' + encodeURIComponent(event.eventDesc);

      if (event.eventLink && event.eventLink !== '') {
        link = link + '%0A%0A' + event.eventLink;
      }
    }


    let el = document.createElement('a');
    el.setAttribute('href', link);
    el.setAttribute('target', '_blank');
    el.style.display = 'none';

    // console.log('google', link);
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);

    // GA Event
    this.ga.sendEvent('VendorPortal','Add Event ' + event.eventName + '  to Google Calendar','Events');
  }

  viewAttachment(file,modal) {
    // access download function
    console.log(file);
    let downloadPath = file.docPath;
    this.docs.downloadDocument(downloadPath, true).subscribe(
      data => {
        console.log('downloading attachment from ' + file.id);
        saveAs(data, file.fileName);
        this.ga.track('/VendorPortal/attachment/download/' + file.fileName);
      },
      error => {
        console.log(error);
        this.modalContent.header = 'Error';
        this.modalContent.body = 'There was a problem downloading the attachment.';
        this.modalContent.footer = error.text();
        this.ga.track('/VendorPortal/error/download/' + file.fileName);
        modal.show();
      }
    );
  }

}