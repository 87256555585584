import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { map, filter, switchMap } from 'rxjs/operators';
import {Observable, BehaviorSubject, from } from 'rxjs';

declare var Keycloak: any;
declare var document;

@Injectable()
export class UserService {

  static auth: any = {};

  // Groups and Roles
  public roles = [
    'TSCAdmin',
    'VendorAdmin',
    'Accounting',
    'Chief Executive Officer',
    'EDI',
    'Inventory',
    'Logistics',
    'Marketing',
    'Open Orders',
    'President',
    'Product Content',
    'Risk Manager',
    'Vendor Compliance',
    'Vendor Funding'

  ];

  public rolesMap = [
      {name: 'TSCAdmin', abbr:'TSCAdmin'},
      {name: 'VendorAdmin', abbr: 'SC'},
      {name: 'Accounting', abbr: 'AT'},
      {name: 'Open Orders', abbr: 'CS'},
      {name: 'Inventory', abbr: 'NC'},
      {name: 'EDI', abbr: 'ED'},
      {name: 'Product Content', abbr: 'PC'},
      {name: 'Vendor Funding', abbr: 'NS'},
      {name: 'Risk Manager', abbr: 'IC'},
      {name: 'Logistics', abbr: 'LC'},
      {name: 'Marketing', abbr: 'MC'},
      {name: 'President', abbr: 'PR'},
      {name: 'Chief Executive Officer', abbr: 'CE'},
      {name: 'Vendor Compliance', abbr: 'VC'}
  ]

  public lineOfBusiness = [
    'AG & REC LINE OF BUSINESS',
    'CLOTHING & GIFT LINE OF BUSINESS',
    'COMPANION ANIMAL LINE OF BUSINESS',
    'EQUINE LVSTK & POULTRY LINE OF BUSINESS',
    'SEASONAL LINE OF BUSINESS',
    'SPECIAL ORDER LINE OF BUSINESS',
    'TRUCK & TOOL & HARDWARE LINE OF BUSINESS'
  ];

  public buyerIds = [
      {
        "buyerId": "100",
        "buyerFirstName": "Anne",
        "buyerLastName": "Fasone",
        "buyerName": "ANNE FASONE"
      },
      {
        "buyerId": "100",
        "buyerFirstName": "Tyler",
        "buyerLastName": "Potts",
        "buyerName": "TYLER POTTS"
      },
      {
        "buyerId": "101",
        "buyerFirstName": "Ruben",
        "buyerLastName": "Barrera",
        "buyerName": "RUBEN BARRERA"
      },
      {
        "buyerId": "101",
        "buyerFirstName": "Anne",
        "buyerLastName": "Fasone",
        "buyerName": "ANNE FASONE"
      },
      {
        "buyerId": "102",
        "buyerFirstName": "Allison",
        "buyerLastName": "Koenig",
        "buyerName": "ALLISON KOENIG"
      },
      {
        "buyerId": "102",
        "buyerFirstName": "Briana",
        "buyerLastName": "Kupelian",
        "buyerName": "BRIANA KUPELIAN"
      },
      {
        "buyerId": "102",
        "buyerFirstName": "Isabel",
        "buyerLastName": "Rzezniczek",
        "buyerName": "ISABEL RZEZNICZEK"
      },
      {
        "buyerId": "103",
        "buyerFirstName": "Kelli",
        "buyerLastName": "Cranford",
        "buyerName": "KELLI CRANFORD"
      },
      {
        "buyerId": "103",
        "buyerFirstName": "Alan",
        "buyerLastName": "Noblitt",
        "buyerName": "ALAN NOBLITT"
      },
      {
        "buyerId": "104",
        "buyerFirstName": "Anna Laura",
        "buyerLastName": "Wells",
        "buyerName": "ANNA LAURA WELLS"
      },
      {
        "buyerId": "104",
        "buyerFirstName": "Todd",
        "buyerLastName": "Williams",
        "buyerName": "TODD WILLIAMS"
      },
      {
        "buyerId": "104",
        "buyerFirstName": "Ryan",
        "buyerLastName": "Wolter",
        "buyerName": "RYAN WOLTER"
      },
      {
        "buyerId": "105",
        "buyerFirstName": "Joe",
        "buyerLastName": "Desjean",
        "buyerName": "JOE DESJEAN"
      },
      {
        "buyerId": "105",
        "buyerFirstName": "Anna Laura",
        "buyerLastName": "Wells",
        "buyerName": "ANNA LAURA WELLS"
      },
      {
        "buyerId": "106",
        "buyerFirstName": "Tyler",
        "buyerLastName": "Henley",
        "buyerName": "TYLER HENLEY"
      },
      {
        "buyerId": "106",
        "buyerFirstName": "Alan",
        "buyerLastName": "Noblitt",
        "buyerName": "ALAN NOBLITT"
      },
      {
        "buyerId": "107",
        "buyerFirstName": "Martin",
        "buyerLastName": "Fysh",
        "buyerName": "MARTIN FYSH"
      },
      {
        "buyerId": "107",
        "buyerFirstName": "Mark",
        "buyerLastName": "Topol",
        "buyerName": "MARK TOPOL"
      },
      {
        "buyerId": "108",
        "buyerFirstName": "Sophie",
        "buyerLastName": "LeBlanc",
        "buyerName": "SOPHIE LEBLANC"
      },
      {
        "buyerId": "108",
        "buyerFirstName": "Isabel",
        "buyerLastName": "Rzezniczek",
        "buyerName": "ISABEL RZEZNICZEK"
      },
      {
        "buyerId": "111",
        "buyerFirstName": "Alan",
        "buyerLastName": "Noblitt",
        "buyerName": "ALAN NOBLITT"
      },
      {
        "buyerId": "111",
        "buyerFirstName": "Stephanie",
        "buyerLastName": "Wright",
        "buyerName": "STEPHANIE WRIGHT"
      },
      {
        "buyerId": "112",
        "buyerFirstName": "Chip",
        "buyerLastName": "Chiappetta",
        "buyerName": "CHIP CHIAPPETTA"
      },
      {
        "buyerId": "112",
        "buyerFirstName": "Anne",
        "buyerLastName": "Fasone",
        "buyerName": "ANNE FASONE"
      },
      {
        "buyerId": "113",
        "buyerFirstName": "Isabel",
        "buyerLastName": "Rzezniczek",
        "buyerName": "ISABEL RZEZNICZEK"
      },
      {
        "buyerId": "113",
        "buyerFirstName": "Chris",
        "buyerLastName": "Spadora",
        "buyerName": "CHRIS SPADORA"
      },
      {
        "buyerId": "114",
        "buyerFirstName": "Jake",
        "buyerLastName": "Guthrie",
        "buyerName": "JAKE GUTHRIE"
      },
      {
        "buyerId": "114",
        "buyerFirstName": "Anna Laura",
        "buyerLastName": "Wells",
        "buyerName": "ANNA LAURA WELLS"
      },
      {
        "buyerId": "115",
        "buyerFirstName": "Becca",
        "buyerLastName": "Bodemer",
        "buyerName": "BECCA BODEMER"
      },
      {
        "buyerId": "115",
        "buyerFirstName": "Mark",
        "buyerLastName": "Topol",
        "buyerName": "MARK TOPOL"
      },
      {
        "buyerId": "115",
        "buyerFirstName": "Sandeep",
        "buyerLastName": "Wajapey",
        "buyerName": "SANDEEP WAJAPEY"
      },
      {
        "buyerId": "116",
        "buyerFirstName": "Alan",
        "buyerLastName": "Noblitt",
        "buyerName": "ALAN NOBLITT"
      },
      {
        "buyerId": "116",
        "buyerFirstName": "Scott",
        "buyerLastName": "Whitted",
        "buyerName": "SCOTT WHITTED"
      },
      {
        "buyerId": "117",
        "buyerFirstName": "Kelly",
        "buyerLastName": "Schroeder",
        "buyerName": "KELLY SCHROEDER"
      },
      {
        "buyerId": "117",
        "buyerFirstName": "Mark",
        "buyerLastName": "Topol",
        "buyerName": "MARK TOPOL"
      },
      {
        "buyerId": "118",
        "buyerFirstName": "Anne",
        "buyerLastName": "Fasone",
        "buyerName": "ANNE FASONE"
      },
      {
        "buyerId": "118",
        "buyerFirstName": "Mollie",
        "buyerLastName": "Treloar",
        "buyerName": "MOLLIE TRELOAR"
      },
      {
        "buyerId": "119",
        "buyerFirstName": "Carol",
        "buyerLastName": "Reed",
        "buyerName": "CAROLE REED"
      },
      {
        "buyerId": "119",
        "buyerFirstName": "Anna Laura",
        "buyerLastName": "Wells",
        "buyerName": "ANNA LAURA WELLS"
      },
      {
        "buyerId": "120",
        "buyerFirstName": "Jeremy",
        "buyerLastName": "Black",
        "buyerName": "JEREMY BLACK"
      },
      {
        "buyerId": "120",
        "buyerFirstName": "Anne",
        "buyerLastName": "Fasone",
        "buyerName": "ANNE FASONE"
      },
      {
        "buyerId": "120",
        "buyerFirstName": "Mike",
        "buyerLastName": "Kassees",
        "buyerName": "MIKE KASSEES"
      },
      {
        "buyerId": "121",
        "buyerFirstName": "Alan",
        "buyerLastName": "Noblitt",
        "buyerName": "ALAN NOBLITT"
      },
      {
        "buyerId": "121",
        "buyerFirstName": "Megan",
        "buyerLastName": "Rees",
        "buyerName": "MEGAN REES"
      },
      {
        "buyerId": "225",
        "buyerFirstName": "Abhilash",
        "buyerLastName": "Bola",
        "buyerName": "ABHILASH BOLA"
      },
      {
        "buyerId": "400",
        "buyerFirstName": "Jed",
        "buyerLastName": "Campfield",
        "buyerName": "JED CAMPFIELD"
      },
      {
        "buyerId": "401",
        "buyerFirstName": "Zoe",
        "buyerLastName": "Haislip",
        "buyerName": "ZOE HAISLIP"
      },
      {
        "buyerId": "402",
        "buyerFirstName": "Alyssa",
        "buyerLastName": "Belgrave",
        "buyerName": "ALYSSA BELGRAVE"
      },
      {
        "buyerId": "499",
        "buyerFirstName": "Todd",
        "buyerLastName": "Milliken",
        "buyerName": "TODD MILLIKEN"
      }

   ]
  

  public groups = [];


  private username: string;
  private token: string;

  public loggedInUser = null;

  public devUsers = [
  ]

  // public vendorTypes = [];

  vendorTypes = new BehaviorSubject({});

  constructor(
    private rest:RestService,
  ) { }

  static init(): Promise<any> {
    let keycloakAuth: any = new Keycloak('assets/' + environment.keycloakfile);
    console.log('keycloakAuth');

    UserService.auth.loggedIn = false;
    UserService.auth.initTimeStamp = Date.now();

      return new Promise((resolve, reject) => {
         keycloakAuth.init({ onLoad: 'login-required', checkLoginIframe: false })

         .success(() => {
          console.log('keycloakAuth success');
          UserService.auth.loggedIn = true;
          UserService.auth.authz = keycloakAuth;

          console.log(keycloakAuth);

          let subject = UserService.auth.authz.subject;

          let token = UserService.auth.authz.token;

          let base64 = UserService.auth.authz.idToken;

          UserService.auth.logoutUrl = keycloakAuth.authServerUrl
                + "/realms/Vendor/protocol/openid-connect/logout?id_token_hint=" + base64 
                + "&post_logout_redirect_uri=" + environment.appurl;
          console.log('UserService.auth.logoutUrl:' + UserService.auth.logoutUrl);

            let name = UserService.auth.authz.idTokenParsed.name;
            console.log('name:' + name);

            let username = UserService.auth.authz.idTokenParsed.preferred_username;
            console.log('username:' + username);

            let email = UserService.auth.authz.idTokenParsed.email;
            console.log('email:' + email);

            let given_name = UserService.auth.authz.idTokenParsed.given_name;
            console.log('given_name:' + given_name);

            let family_name = UserService.auth.authz.idTokenParsed.family_name;
            console.log('family_name:' + family_name);

            let resourceAccess = UserService.auth.authz.resourceAccess;
            console.log(resourceAccess);
            if(resourceAccess && resourceAccess['vendor-portal-ui'] && resourceAccess['vendor-portal-ui']['roles']){
              UserService.auth.authUserRoles = resourceAccess['vendor-portal-ui']['roles'];
              console.log('User ' +  username + ' has ' + UserService.auth.authUserRoles.length + ' roles: ' + UserService.auth.authUserRoles.join(','));
            }

            resolve();
          })
          .error(() => {
            console.log('keycloakAuth error');
            reject();
          });
      });
    }

    public getUniqueID() {
      return UserService.auth.authz.subject;
    }


    logout() {
      console.log('LOGOUT');
      UserService.auth.loggedIn = false;
      UserService.auth.authz = null;
      this.loggedInUser = null;

      window.location.href = UserService.auth.logoutUrl;
    }


    getToken(): Promise<string> {
    
    return new Promise<string>((resolve, reject) => {
      if (UserService.auth.authz.token) {
        UserService.auth.authz.updateToken(120)
          .success(() => {
            // console.log('updated token' + UserService.auth.authz.token);
            resolve(<string>UserService.auth.authz.token);
          })
          .error(() => {
            console.log('token refresh failed ' + UserService.auth.authz.token);
            reject('Failed to refresh token');
          });
          // resolve(<string>UserService.auth.authz.token);
        }
    });
    }

    getKeyCloakToken(){
      console.log('get keycloak token');
      this.getToken();
      return UserService.auth.authz.token;
    }

    getHeader() {
      return "Bearer " + this.getKeyCloakToken();
    }


  // get user role to determine permissions

  getUser() {
    if(UserService.auth.loggedIn === true){
      /*
      this.loggedInUser = {
        'id': UserService.auth.authz.subject,
        'username': UserService.auth.authz.idTokenParsed.preferred_username,
        'groupName': UserService.auth.authUserRoles[0],
        'firstname': UserService.auth.authz.idTokenParsed.given_name,
        'lastname': UserService.auth.authz.idTokenParsed.family_name,
      };
      */
     console.log(UserService.auth.authUserRoles);
     this.loggedInUser = new User();
     this.loggedInUser.id = UserService.auth.authz.subject;
     // this.loggedInUser.userName = UserService.auth.authz.idTokenParsed.preferred_username;
     this.loggedInUser.userName = UserService.auth.authz.idTokenParsed.email;
     this.loggedInUser.firstName = UserService.auth.authz.idTokenParsed.given_name;
     this.loggedInUser.lastName = UserService.auth.authz.idTokenParsed.family_name;
     if(UserService.auth.authUserRoles && UserService.auth.authUserRoles.length > 0){
      if (UserService.auth.authUserRoles.indexOf('VMD') > -1) {
        this.loggedInUser.groupName = 'VMD';
      } else if (UserService.auth.authUserRoles.indexOf('TSCAdmin') > -1) {
        this.loggedInUser.groupName = 'TSCAdmin';
      } else if (UserService.auth.authUserRoles.indexOf('VendorAdmin') > -1) {
        this.loggedInUser.groupName = 'VendorAdmin';
      } else if (UserService.auth.authUserRoles.indexOf('TSC_Buyer') > -1) {
        this.loggedInUser.groupName = 'TSC_Buyer';
      }
      else if (UserService.auth.authUserRoles.indexOf('PTS_Buyer') > -1) {
        this.loggedInUser.groupName = 'PTS_Buyer';
      }else {
        this.loggedInUser.groupName = 'Vendors';
      }
    }
    }
    console.log('Group name', this.loggedInUser.groupName);

    // get vendor info
    this.getUserDetails(this.loggedInUser).subscribe(
      data => {
        console.log(data);
        if(data && data.json()){
          let userdata = data.json()[0];
          if(userdata && userdata.vendorName){
            this.loggedInUser.vendorName = userdata.vendorName;
          }
          if(userdata && userdata.vendorNumber){
            this.loggedInUser.vendorNumber = userdata.vendorNumber;
          }
          if(userdata && userdata.vendorType){
            this.loggedInUser.vendorType = userdata.vendorType;
          }//added vendorType
        }
      }
    );

    this.requestUserRoles(this.loggedInUser).subscribe(
      data => {
        if(data){
          if(data.json()){
            const resp = data.json();
            console.log('request user roles: ', resp);

            if (resp){
              this.loggedInUser.roleName = resp.roleDetails.toString();

              if(resp.buyerIdDetails){
                // console.log('add buyer id', resp.buyerIdDetails);
                this.loggedInUser.buyerId = resp.buyerIdDetails;
              }
              if(resp.lobDetails){
                this.loggedInUser.lob = resp.lobDetails;
              }
            }
          }
        }

      }, error => {
          console.log(error);

      }
    );

    // vendor attributes
    const userEmail = {'Email': this.loggedInUser.userName};
    this.rest.postReq('getvendorattributesbyemail', userEmail, true).subscribe(
      va => {
        console.log('vendor attributes for ' + this.loggedInUser.userName, va);
        // assign vendor attributes
        let attrinfo = va.json();
        this.loggedInUser.vendorList = attrinfo['Vendors'];

      }
    );
    return this.loggedInUser;

  }

  getUserRole() {
    // console.log('get user role for logged in User', this.loggedInUser);
    if (this.loggedInUser && this.loggedInUser.groupName){
      if (this.loggedInUser.groupName === 'Vendors' && this.loggedInUser.roleName){
        // get the vendor role(s)
        return this.loggedInUser.roleName;
      } else {
        return this.loggedInUser.groupName;
      }
    } else {
      return null;
    }
  }

  getSessionId() {
    return UserService.auth.authz.sessionId;
  }

  sortByDate(data, fieldname) {
    if (data[0][fieldname]) {
      data.sort((a, b) => (new Date(a[fieldname].toLowerCase()) > new Date(b[fieldname].toLowerCase())) ? 1 : -1);
    }
    return data;
  }

  sortBy(data, fieldname) {
    if (data[0][fieldname]) {
      data.sort((a, b) => (this.getLowerCase(a[fieldname]) > this.getLowerCase(b[fieldname])) ? 1 : -1);
    }
    return data;
  }

  getLowerCase(string) {
    if (string) {
      return string.toLowerCase();
    }
    return '';
  }

  requestUserRoles(user) {
    const url = 'getRoleDetails';
    const json = {
      'userName': user.userName
    }
    return this.rest.postReq(url, json, this.getHeader()).pipe(map(
      data => {
        console.log('request user roles: ', data);
        return data;
      }
    ));

  }

  requestAvailableRoles(){

    // dynamic role list - needs service change to handle multiple roles
    const url = 'getRoleDetails';
    const json = {
      'userName': 'ALL'
    }

    return this.rest.postReq(url, json, this.getHeader()).pipe(map(
      data => {
         console.log(data);
         return data;

      }
    ));


  }

  isLoggedIn(){
    return UserService.auth.loggedIn;
   // return true;
  }

  isAuthenticated(){


    return true;
  }


  // Groups and Roles



  // Users list (vendor)
  getUsersForVendor(){
    // require vendorAdmin permission
    // if (!this.loggedInUser || this.loggedInUser.groupName !=='VendorAdmin') {
    /*
    if (!this.loggedInUser || this.loggedInUser.role !=='VendorAdmin') {
      return Observable.of(null); // empty array
    }
    */
    // request all users from logged in vendor
    // const vendorID = this.loggedInUser.vendorid;
    // req.vendorid = vendorID;
    let req = {
      'loggedUser': this.loggedInUser.userName,
      'vendorNumber': this.loggedInUser.vendorNumber
    };
       console.log(req);

    // call rest service
    const url = 'getSearchUserDetails';

    return this.rest.postReq(url, req, this.getHeader()).pipe(map(
      data => {
        console.log(data);
        return data;
      }, error => {
        console.log(error);
        return error;
      }
    ));

    /*
    console.log('get users for' + vendorID);
    const uList = this.devUsers.filter(u => u.vendorid === vendorID); // dev
    console.log('userlist', uList);
    return uList;
    */
  }

  editVendorUser(user){
    console.log('edited user', user);
    // update SSO first
    const url = 'createUserCheck';
    const auth = this.getHeader();
    return this.rest.postReq(url, user, auth).pipe(map(
      resp => {
        //return data;
        console.log('Edit Vendor User response', resp);
        return this.updateUser(user).pipe(map(
          data => {
            console.log('Edit Vendor User response', data);
            return data;
          }, error => {
            return error;
          }
        ));
      }, error => {
        return error;
      }
    ));
  }

  updateUser(user){
    // force update database
    const url = 'updateUser';
    const auth = this.getHeader();
    return this.rest.postReq(url,user,auth).pipe(map(
      data => {
        return data;
      }, error => {
        return error;
      }
    ));
  }

  getUserDetails(user){
    // return vendor info
    let req = {
      'loggedUser': this.loggedInUser.userName,
      'userName': user.userName
    };
       console.log(req);

    // call rest service
    const url = 'getSearchUserDetails';
    return this.rest.postReq(url, req, this.getHeader()).pipe(map(
      data => {
        return data;
      }, error => {
        return error;
      }
    ));
  }


  deleteUser(username){
    const url = 'deleteUser';
    const req = {'userName':username};
    const auth = this.getHeader();
    return this.rest.delReq(url, req, auth).pipe(map(
      data => {
        console.log('delete user', username, data);
        return data;
      }, error => {
        console.log(error);
        return error;
      }
    ));

  }

  getLOB(id){
    // get line of business for a vendor

  }

  getBuyerId(id) {
    // get Buyer ID for a vendor

  }

  POST(url,req, auth?){
    // refresh token, then make POST request
    return from(this.getToken().then(
      res =>{
        return this.rest.postReq(url, req, this.getHeader());
    }, error => {
        console.log('authorization error', error);
        return error;
    })
    );
  }





  // DEV functions

  setUser(id){
    this.loggedInUser = this.devUsers.find(u => u.id === id);
    for (let user of this.devUsers){
      if(user.id === id){
        this.loggedInUser = user;
      }
    }
    console.log(this.loggedInUser);

  }

}

export class User {
  public id: string;
  public userName: string;
  public roleName: string;
  public groupName: string;
  public firstName: string;
  public lastName: string;
  public vendorName: string; // vendor name
  public vendorNumber: string; // vendor number
  public createDate: string;
  public createBy: string;
  public modifyDate: string;
  public lastModifiedBy: string;
  public vendorType: string;

  public lob: string;
  public buyerId: string;
  public rolesArr: String[] = [];
}

export class newUserRequest {
  public loggedUser: string;
  public userName: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public groupName: string;
  public roleName: string;
  public vendorName: string;
  public vendorNumber: string;
  public createBy: string;
  public createDate: string;
  public vendorType: string;

  // public lob: string;
  // public buyerId: string;
}
