import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentService, Doc } from './../services/document.service';
import { saveAs } from 'file-saver';

 
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  testDocsList = [
    {
      'filename':'TSC-05-111GC Standard Vendor Agreement.pdf',
      'filesize':'234k',
      'lastupdated':'2019-06-12 09:42',
      'selected':false,
      'url':'assets/docs/TSC-05-111GC Standard Vendor Agreement.pdf',
      'permissions':''
    },
    {
      'filename':'TSC-00-00-7 Regulations by State and Region.pdf',
      'filesize':'15378k',
      'lastupdated':'2019-06-12 09:42',
      'selected':false,
      'url':'assets/docs/TSC-00-00-7 Regulations by State and Region.pdf',
      'permissions':''
    },
    {
      'filename':'SV-110 Licensing Addendum for Livestock.docx',
      'filesize':'156k',
      'lastupdated':'2019-06-12 09:42',
      'selected':false,
      'url':'assets/TSC-05-111GC Standard Vendor Agreement.pdf',
      'permissions':''
    },
    {
      'filename':'SV-112 Marketing Requirements.docx',
      'filesize':'488k',
      'lastupdated':'2019-06-12 09:42',
      'selected':false,
      'url':'assets/docs/SV-112 Marketing Requirements.docx',
      'permissions':''
    },
    {
      'filename':'USA-2-45-ii USDA Requirements for Non-Edible Plants section ii.pdf',
      'filesize':'20385k',
      'lastupdated':'2019-06-12 09:42',
      'selected':false,
      'url':'assets/docs/USA-2-45-ii USDA Requirements for Non-Edible Plants section ii.pdf',
      'permissions':''
    }
    
  ];

  docsList: Doc[] = [];
  fullDocsList: Doc[] = [];
  subFolders: any[]=[];
  visibleSubs: any[]=[];

  modalContent = {
    'header': '',
    'body': '',
    'footer': ''
  }

  showMenu = true;

  constructor(
    public docs: DocumentService,
    public router: Router,

  ) { }

  ngOnInit() {

    console.log('Selected Category: ' + this.docs.selectedCategory);
    if (this.docs.selectedCategory!=='' && this.docs.documentCategories.find(c => c.category === this.docs.selectedCategory)) {
      this.showDocs(this.docs.selectedCategory,true);
    }
    else {
      // not clicked directly
      this.showDocs();
    }
    // get documents list
    this.docs.retrieveDocuments().subscribe(
      data => {
        // console.log('document list', data);
        this.fullDocsList = data;
        for (let d of this.fullDocsList){
          if (!d.categoryName || d.categoryName === '') {
            d.categoryName = 'All';
          }

          d.combinedArr = []; //initialize
          // populate combined array for folder/subfolders
          let cats = d.categoryName.split(',');
          console.log(cats);
          for (let c of cats) {
            if (c.indexOf('/') > -1) {
              // get subfolder
              let folderLevel = c.split('/');
              // move to array
              d.combinedArr.push({category:folderLevel[0],subfolder:folderLevel[1]});
            } else {
              // no subfolder
              d.combinedArr.push({category:c, subfolder:''});
            }
          }
        }

        this.docsList = this.fullDocsList;
        console.log('all docs', this.fullDocsList, this.docs.documentCategories);
        console.log('initial category: ' + this.docs.selectedCategory);
        if (this.docs.selectedCategory !== '' && this.docs.documentCategories.find(c => c.category === this.docs.selectedCategory)) {
          this.docsList = this.fullDocsList.filter(d => d.categoryName === '' || d.categoryName === 'All' || d.categoryName.indexOf(this.docs.selectedCategory) > -1);
          console.log('category docs', this.docsList);

          // parse subcategories
          let docCat = this.docs.documentCategories.find(c => c.category === this.docs.selectedCategory);
          if (docCat) {
            this.subFolders = docCat.subfolders;
          }
          console.log(this.subFolders, docCat);
          this.docsList.sort(this.sortBy('name'));
        }
      }
    );

    let dis = this;
    window.addEventListener('popstate',
    () => {
      if (this.docs.selectedCategory !== '') {
        this.docs.selectedCategory = '';
        this.showMenu = true;
      } else {
        this.showMenu = true;
      }
    }
);
  }

  selectDownload(id){
    if (this.docsList[id].selected) {
      this.docsList[id].selected = false;
    } else {
      this.docsList[id].selected = true;
    }
    console.log('selected ' + this.docsList[id].fileName, this.docsList);
  }

  downloadDocs(modal) {
    let selectedDocs = this.docsList.filter(d => d.selected === true);
    let downloadedDocs = [];
    for (let d of selectedDocs){
      console.log('downloading ' + d.fileName);

      // use download document service, returns the files

      this.docs.downloadDocument(d).subscribe(
        data => {
          saveAs(data, d.fileName.toString());
          downloadedDocs.push(d);
        }, error => {
          console.log(error);
          this.modalContent.header = 'Error';
          this.modalContent.body = 'There was a problem downloading the document.';
          this.modalContent.footer = error.text();
          modal.show();
        }
      );

      

    }
    let files = downloadedDocs.map(d => d.filename);
    console.log('Downloaded ' + downloadedDocs.length + ' files: ', files);
  }

  downloadDoc(d,modal){
    // single file download
    this.docs.downloadDocument(d).subscribe(
      data => {
        saveAs(data, d.fileName);
      }, error => {
        console.log(error);
        this.modalContent.header = 'Error';
        this.modalContent.body = 'There was a problem downloading the document.';
        this.modalContent.footer = error.text();
        modal.show();
      }
    );
  }


  disableDownload(){
    let disabled = true;
    let selected = this.docsList.filter(d => d.selected === true);
    if(selected.length > 0){
      disabled = false;
    }
    return disabled;
  }

  showDocsMenu(){
    return this.showMenu;
  }

  toggleSub(index){
    if(!this.visibleSubs[index]){
      this.visibleSubs[index] = true;
    } else {
      this.visibleSubs[index] = false;
    }
  }

  showSub(index){
    return this.visibleSubs[index];
  }

  subEmpty(sub) {
    for (let d of this.docsList) {
      for (let c of d.combinedArr) {
        if (c.subfolder && c.subfolder === sub){
          return false;
        }
      }
    }
    return true;
  }

  showDocs(cat?,home?){
    if(cat){
      this.showMenu = false;
      this.docs.selectedCategory = cat;
      if (this.docs.documentCategories.find(c => c.category === this.docs.selectedCategory)) {
        this.docsList = this.fullDocsList.filter(d => d.categoryName === '' || d.categoryName === 'All' || d.categoryName.indexOf(this.docs.selectedCategory) > -1);
        // parse subcategories
        let docCat = this.docs.documentCategories.find(c => c.category === this.docs.selectedCategory);
        if (docCat) {
          this.subFolders = docCat.subfolders;
        }
        console.log(this.docs.selectedCategory, this.docsList);
        
        if (!home) {
          window.history.pushState({},'Documents','/documents?');
        }
      } else {
        this.docsList = this.fullDocsList;
      }
    }else{
      this.showMenu = true;
      this.docs.selectedCategory = '';
      this.docsList = this.fullDocsList;
    }
    this.docsList.sort(this.sortBy('name'));
  }

  sortBy(key, order?) {
      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          return 0;
        }
    
        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key];
    
        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        );
      };
   }

   hasSub(doc, sub) {
    for (let f of doc.combinedArr) {
      if (f.subfolder === sub){
        return true;
      }
    }
    return false;
   }

   noSub(doc, category) {
     let showInFolder = false;
     for (let f of doc.combinedArr) {
       if (f.category === category || f.category === 'All') {
          if (!f.subfolder || f.subfolder === ''){
            showInFolder = true;
          }
        }
     }
     return showInFolder;
   }

}
