<div class="calendar">
  <div class="calendar-navs">
    <div class="month-nav">
      <button class="prev" (click)="prevMonth()"><i class="material-icons">arrow_left</i></button>
      <span class="calendar-header">{{ currentDate.format('MMMM YYYY') }}</span>
      <button class="next" (click)="nextMonth()"><i class="material-icons">arrow_right</i></button>
    </div>
  </div>
  <div class="month-grid">
    <div class="day-names">
      <div *ngFor="let name of dayNames" class="day-name p9">
        {{ name }}
      </div>
    </div>
    <div class="weeks">
      <div *ngFor="let week of weeks" class="week">
        <ng-container *ngFor="let day of week">
          <div class="week-date disabled" *ngIf="!isSelectedMonth(day.mDate)">
            <span class="date-text">{{ day.mDate.date() }}</span>
          </div>
          <div class="week-date enabled"
               *ngIf="isSelectedMonth(day.mDate)"
               (click)="selectDate(day)"
               [ngClass]="{ today: day.today, selected: day.selected }">
            <span class="date-text">{{ day.mDate.date() }}</span>
            <div class="event-list">
              <div *ngFor="let event of events.eventList">
                <div *ngIf="listEvent(day,event)" class="event" (click)="showEvent(event.id,details)">{{ event.eventName }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="mobile-event-list">
      <div *ngFor="let event of events.eventList">
        <div *ngIf="isSelectedMonth(event.startDate)" class="mobile-event" (click)="showEvent(event.id,details)">
          <div class="mobile-event-date">{{ event.startDate | date:'d' }}<!--<span *ngIf="showEndDate(event)">~{{ event.endDate | date:'d' }}</span>--></div>
          <div class="mobile-event-data">
            <span class="mobile-event-name">{{ event.eventName }}</span>
            <span class="mobile-event-location" *ngIf="event.eventLocation"><br />{{ event.eventLocation }}</span>
          </div>
        </div>
      </div>
    </div>

    <app-event-modal #details>
      <div class="app-modal-header">{{ selectedEvent?.eventName }}</div>
      <div class="app-modal-body event-body">
        <div class="event-date">{{ selectedEvent?.startDate | date:'MMMM d, yyyy h:mm a' }}
          <span *ngIf="selectedEvent && isMulti(selectedEvent)"> - {{ selectedEvent?.endDate | date:'MMMM d, yyyy h:mm a' }}</span>
          <span *ngIf="selectedEvent && !isMulti(selectedEvent)"> - {{ selectedEvent?.endDate | date: 'h:mm a' }}</span>
        </div>
        <div class="event-location">{{ selectedEvent?.eventLocation }}</div>
        <div class="event-desc" [innerHTML]="selectedEvent?.eventDesc"></div>
        <div *ngIf="selectedEvent?.eventLink && selectedEvent.eventLink!==''" class="event-link"><a href="{{ selectedEvent?.eventLink }}" target="_blank">{{ selectedEvent?.eventLink }}</a></div>
        <div class='event-attachment'><button *ngIf="selectedEvent?.docPath && selectedEvent.docPath !== ''" class="btn link-btn attachment" (click)="viewAttachment(selectedEvent,modalMessage)" title="{{ selectedEvent?.fileName }}">View Attachment</button></div>
      </div>
      <div class='app-modal-footer'>
        <div class='event-footer'>Add to my calendar: <button (click)="addToCalendar(selectedEvent)" class='add-to-calendar'>Outlook</button> <button (click)="addToGoogleCalendar(selectedEvent)" class='add-to-calendar'>Google</button></div>
      </div>
    </app-event-modal>
  </div>
</div>
