import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { SharedModule } from "./shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";

// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { AuthTokenInterceptor } from './shared/authtoken.interceptor';

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { AccountComponent } from "./account/account.component";
import { HomeComponent } from "./home/home.component";
import { DocumentsComponent } from "./documents/documents.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { FaqComponent } from "./faq/faq.component";
import { ConsentComponent } from "./consent/consent.component";
import {DatePipe, CurrencyPipe} from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

// import { ModalComponent } from './modal/modal.component';

import { AnnouncementService } from "./services/announcement.service";
import { EventService } from "./services/event.service";
import { UserService } from "./services/user.service";
import { DocumentService } from "./services/document.service";
import { LinkService } from "./services/link.service";
import { GuardService } from "./services/guard.service";
import { RestService } from "./services/rest.service";
import { GoogleAnalyticsService } from "./services/analytics.service";

import { InviteService } from "./services/invite.service";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { VendorInviteComponent } from "./vendor-invite/vendor-invite.component";
import { HttpClientModule } from "@angular/common/http";

import { AddressService } from "./services/address.service";
import { DialogComponent } from './dialog/dialog.component';
import { DisplaydialogComponent } from './displaydialog/displaydialog.component';
import {MaterialModule} from './shared/material.module';
import { AddVendorComponent } from './add-vendor/add-vendor.component';
import { BuyerAddVendorComponent } from './buyer-add-vendor/buyer-add-vendor.component';
import { MaintenancebuyerComponent } from './maintenancebuyer/maintenancebuyer.component';
import { VolumeRebateComponent } from './volume-rebate/volume-rebate.component';
import { VacComponent } from './vac/vac.component';
import { VacMaintenanceComponent } from './vac-maintenance/vac-maintenance.component';
import { VacService } from "./services/vac.service";
// import { VacGridComponent } from './vac/vac-grid/vac-grid.component';
import { HotTableModule } from 'ng2-handsontable';
import { DisableFormControlDirective } from './volume-rebate/directive/disable-form-control.directive';
import { VolumeRebateSearchComponent } from './volume-rebate/volume-rebate-search/volume-rebate-search.component';

import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { PowerBiComponent } from './campaign-performance/campaign-performance.component';

import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { ManageInvitesComponent } from './manage-invites/manage-invites.component';
import { ManageInvitesService } from "./services/manage-invites.service";

const routes: Routes = [
  { path: "account", component: AccountComponent },
  { path: "vendorInvite", component: VendorInviteComponent },
  { path: "addVendor", component: AddVendorComponent },
  { path: "documents", component: DocumentsComponent },
  { path: "calendar", component: CalendarComponent },
  { path: "consent", component: ConsentComponent },
  { path: "buyerAdditionalVendor",component: BuyerAddVendorComponent },
  { path: "buyerMaintenance",component: MaintenancebuyerComponent },
  { path: "volumeRebate",component: VolumeRebateComponent },
  { path: "volumeRebateSearch",component: VolumeRebateSearchComponent },
  { path: "vendorAllowance",component: VacComponent },
  { path: "vacMaintenance",component: VacMaintenanceComponent },
  { path: "faq", component: FaqComponent },
  { path: "campaign-performance", component: PowerBiComponent },
  { path: "admin", loadChildren: "./admin/admin.module#AdminModule" },
  { path: "vendor-onboarding",
    loadChildren:
      "./vendor-onboarding/vendor-onboarding.module#VendorOnboardingModule",
    canActivate: [GuardService]
    // data: {roles: ['TSCAdmin', 'Vendor']}
  },
  { path: "manage-invites", component: ManageInvitesComponent },
  { path: 'vendor-dashboard', loadChildren: './vendor-dashboard/vendor-dashboard.module#VendorDashboardModule' },
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', redirectTo: '/error', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AccountComponent,
    HomeComponent,
    DocumentsComponent,
    CalendarComponent,
    FaqComponent,
    ErrorPageComponent,
    VendorInviteComponent,
    ConsentComponent,
    DialogComponent,
    DisplaydialogComponent,
    AddVendorComponent,
    BuyerAddVendorComponent,
    MaintenancebuyerComponent,
    VolumeRebateComponent,
    VacComponent,
    VacMaintenanceComponent,
    DisableFormControlDirective,
    VolumeRebateSearchComponent,
    PowerBiComponent,
    PopupDialogComponent,
    ManageInvitesComponent,
    // VacGridComponent,
    //ModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    HotTableModule,
    PowerBIEmbedModule,
    MatButtonModule,
    RouterModule.forRoot(routes)
  ],
  providers: [
    AnnouncementService,
    EventService,
    UserService,
    DocumentService,
    LinkService,
    GuardService,
    RestService,
    GoogleAnalyticsService,
    InviteService,
    AddressService,
    DatePipe,
    CurrencyPipe,
    CookieService,
    VacService,
       /* { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true } */

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
  ],
  //exports: [ModalComponent],
  entryComponents: [DialogComponent, PopupDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
