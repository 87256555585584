<div class="container">
  <div class="row">
    <div class="col-md-8 left-column">
      <div class="section-splash" [ngClass]="{'pts':isPTS}"></div>
      <div class="section-news">
        <h2>Announcements</h2>
        <!-- <div *ngIf="latestPost">
          <h3>{{ latestPost.title }}</h3>
          <p>{{ latestPost.bodyText }}</p>
          <p *ngIf="(latestPost.announceLink && latestPost.announceLink !== '') || (latestPost.docPath && latestPost.docPath !== '')">
              <button *ngIf="latestPost.announceLink && latestPost.announceLink !== ''" class="btn link-btn" (click)="goToALink(latestPost)" title="{{ latestPost.announceLink }}">Visit Link</button>
              <button *ngIf="latestPost.docPath && latestPost.docPath !== ''" class="btn link-btn attachment" (click)="viewAttachment(latestPost,modalMessage)" title="{{ latestPost.fileName }}">View Attachment</button>
            </p>
        </div> -->
        <div *ngFor="let article of latestPosts" class="announcement row">
          <div class="col-sm-12">
            <h3>{{ article.title }}</h3>
            <p>{{ article.bodyText }}</p>
            <p
              *ngIf="(article.announceLink && article.announceLink !== '') || (article.docPath && article.docPath !== '')">
              <button *ngIf="article.announceLink && article.announceLink !== ''" class="btn link-btn"
                      (click)="goToALink(article)" title="{{ article.announceLink }}">Visit Link
              </button>
              <button *ngIf="article.docPath && article.docPath !== ''" class="btn link-btn attachment"
                      (click)="viewAttachment(article,modalMessage)" title="{{ article.fileName }}">View Attachment
              </button>
            </p>
          </div>
        </div>

        <div class="more-news-list" *ngIf="showMoreNews()">
          <h2>News Archive</h2>
          <div *ngFor="let announcement of newsList; let y = index" class="announcement row">
            <div class="col-sm-10"><!-- *ngIf="y > 2"  -->
              <h4>{{ announcement.title }}</h4>
              <p>{{ announcement.bodyText }}</p>
            </div>
            <div class="col-sm-2 text-right"
                 *ngIf="((announcement.announceLink && announcement.announceLink !== '') || (announcement.docPath && announcement.docPath !== ''))">
              <button *ngIf="announcement.announceLink && announcement.announceLink !== ''" class="btn link-btn"
                      (click)="goToALink(announcement)" title="Visit {{ announcement.announceLink }}"><span
                class="material-icons">open_in_browser</span></button>
              <button *ngIf="announcement.docPath && announcement.docPath !== ''" class="btn link-btn attachment"
                      (click)="viewAttachment(announcement,modalMessage)" title="View {{ announcement.fileName }}"><span
                class="material-icons">attach_file</span></button>
            </div>
          </div>
        </div>
        <div class="more-news" *ngIf="newsList && newsList.length > 3">
          <!-- <button *ngIf="newsBtnText !== 'More News'" class="btn more-btn">View All</button> -->
          <button (click)="toggleMoreNews()" class="btn more-btn">{{ newsBtnText }}</button>
        </div>

      </div>

      <div class="section-documents">
        <h2>Documents</h2>
        <div class="flex-row">
          <ng-container *ngFor="let c of docs.documentCategories">
              <a class="folder" routerLink="documents"
              (click)="selectDocumentCategory(c.category)"> <!-- *ngIf="!isPTS || (isPTS && c.show !== 'TSC')" -->
                <div class="thumbnail" [ngStyle]="{'background-image':'url(assets/' + c.thumbnail + ')'}">
                </div>
                <div class="text">
                  {{ c.category }}
                </div>
              </a>
          </ng-container>
          
        </div>
      </div>

      <div class="section-calendar"><!-- *ngIf="!isPTS" -->
        <h2>Event Calendar - TSC Only</h2>
        <div *ngIf="!showCal" class="calendar-loading">loading calendar...</div>
        <app-calendar *ngIf="showCal"></app-calendar>
      </div>
    </div>
    <div class="col-md-4 right-column">
      <h2>Links</h2>
      <!-- <div class="portal-link">
        <a class="btn btn-success" routerLink="./../campaign-performance">POWER BI DEMO</a>
        <br/>&nbsp;
      </div> -->
      <ng-container *ngFor="let link of linkList">
        <div class="portal-link"> <!-- *ngIf="!isPTS || (isPTS && link.show !== 'TSC')" -->
          <a [ngClass]="{'disabled':link.level && link.level === 'disabled'}" href="{{ link.url }}"
            (click)="visitLink(link);$event.preventDefault()">
            <h4>{{ link.displayname }}</h4>
          </a>
          <p class="description" [innerHTML]="link.desc"></p>
        <span class="description" *ngIf="link.contact && link.contact !=''">Questions? Email <a href="mailto:{{ link.contact }}">{{ link.contact }}</a></span>
        </div>
      </ng-container>
      <div class="portal-link">
        <a routerLink="./../documents">
          <h4>Documents</h4>
        </a>
        <p class="description">Find documents for download and approval</p>
      </div>

      <div class="portal-link">
        <a routerLink="./../faq">Frequently Asked Questions</a>
        <p class="description">Get answers related to the TSC Partner Portal</p>
      </div>
      <!--
       <a href="{{ link.url }}" (click)="visitLink(link);$event.preventDefault()"><h4>{{ link.displayname }}</h4></a>

        <div class="dev-users">
           <label>Select user:&nbsp;</label>
           <select class="form-control" (change)="switchUser($event.target.value)">
             <option value=''>&nbsp;</option>
             <option *ngFor="let user of userList" value="{{user.id}}">{{ user.firstName }} {{ user.lastName }}: {{ user.roleName }}</option>
           </select>
           <button (click)="switchBG()">Change Image</button>
         </div>
       -->
    </div>
  </div>
</div>

<app-modal #modalMessage>
  <div class="app-modal-header">
    {{ modalContent.header }}
  </div>
  <div class="app-modal-body">
    {{ modalContent.body }}
  </div>
  <div class='app-modal-footer'>
    {{ modalContent.footer }}
    <button (click)="modalMessage.hide()">OK</button>
  </div>
</app-modal>
